import {Injectable} from "@angular/core";
import {Estimates} from "@modules/estimate/Domain/EstimateOption/Aggregate/estimates";
import {EstimateRepository} from "@modules/estimate/Domain/EstimateOption/Repository/estimate-repository";

@Injectable({
  providedIn: 'root'
})
export class GetEstimatesByJobIdUseCase {
  constructor(
    private estimateRepository: EstimateRepository
  ) {
  }

  async execute(jobID: string): Promise<Estimates> {
    return this.estimateRepository.getEstimatesByJobID(jobID);
  }
}
