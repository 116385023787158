<ion-list lines="none">
  <app-width-length [form]="form"></app-width-length>
  <ion-item *ngIf="form.get('sqft')?.disabled">
    <ion-note>Please disable roof pitch mode to enter sqft</ion-note>
  </ion-item>
  
  <ion-item *ngIf="displayPitchSelector">
    <ion-toggle
      (ionChange)="roofPitchChanged($event)"
      [(ngModel)]="isPitchFactorEnabled"
    >
      Apply Pitch Factor
    </ion-toggle>
  </ion-item>


  <form [formGroup]="form">
    <ion-item>
      <ion-select
        formControlName="service"
        label="Select Service"
        label-placement="floating"
        placeholder="Select Service"
      >
        <ion-select-option *ngFor="let service of services" [value]="service.value">
          {{ service.value }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-select
        formControlName="depth"
        label="Select Depth"
        label-placement="floating"
        placeholder="Select Depth"
      >
        <ion-select-option *ngFor="let item of availableDepths" [value]="item.value">
          {{ item.value }}
        </ion-select-option>
      </ion-select>
      <ion-icon (click)="openEnergyRecommendationModal()" class="energy-star-recommendation-icon" slot="end"></ion-icon>
    </ion-item>

    <ion-item>
      <ion-select
        formControlName="product"
        label="Select Product"
        label-placement="floating"
        placeholder="Select Product"
      >
        <ion-select-option
          *ngFor="let product of filteredProducts?.getSortedProductsByAlphabet()"
          [value]="product.name"
        >
          {{ product.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <app-crew-selector [form]="form"></app-crew-selector>

    <ion-item>
      <ion-input
        appClearZero
        error-text="Project Job Hours is required"
        formControlName="projHours"
        inputmode="numeric"
        label="Project Job Hours"
        label-placement="floating"
        placeholder="Project Job Hours"
        type="number"
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-input
        appClearZero
        error-text="Miscellaneous is required"
        formControlName="miscellaneous"
        inputmode="numeric"
        label="Miscellaneous"
        label-placement="floating"
        placeholder="Miscellaneous"
        type="number"
      ></ion-input>
    </ion-item>
  </form>
</ion-list>


