<form [formGroup]="form">

  <ion-item>
    <ion-input
      appClearZero
      error-text="Sqft is required"
      formControlName="sqft"
      inputmode="numeric"
      label="Area Square footage"
      label-placement="floating"
      placeholder="Area Square footage"
      type="number"
    >
    </ion-input>
  </ion-item>
</form>

<div *ngIf="!this.form.get('sqft')?.disabled">
  <form *ngIf="showWidthLength " [formGroup]="widthLengthForm">
    <ion-item>
      <ion-input
        error-text="Length is required"
        formControlName="length"
        inputmode="numeric"
        label="Length (Ft)"
        label-placement="floating"
        placeholder="Length (Ft)"
        type="number"
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-input
        error-text="Width is required"
        formControlName="width"
        inputmode="numeric"
        label="Width (Ft)"
        label-placement="floating"
        placeholder="Width (Ft)"
        type="number"
      ></ion-input>
    </ion-item>
  </form>

  <ion-item>
    <ion-toggle (ionChange)="toggleWidthLength()">
      I want to enter width and length
    </ion-toggle>
  </ion-item>
</div>
