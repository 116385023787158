import {Injectable} from '@angular/core';
import {JobID} from "@modules/job/Domain/Job/VO/job-id";
import {
  DynamicsCreateCommand
} from "@modules/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsUpdateCommand
} from "@modules/microsoft-dynamics/Application/UseCase/Command/dynamics-update-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {CreateRequest, DeleteRequest, RetrieveMultipleRequest, RetrieveRequest, UpdateRequest} from "dynamics-web-api";
import {Jobs} from "../../../Domain/Job/Aggregate/jobs";
import {Job} from "../../../Domain/Job/job";
import {JobOptions} from "../../../Domain/Job/job-options";
import {JobRepository} from "../../../Domain/Job/Repostiory/job-repository";
import {DynamicsJobFactory} from "./Factory/dynamics-job-factory";
import {DynamicsJob} from "./Type/dynamics-job";

@Injectable({
  providedIn: 'root'
})
export class DynamicsJobRepository implements JobRepository {

  private readonly tableName = "cr9b4_ifoamjobs";

  constructor(
    private readonly dynamicsJobFactory: DynamicsJobFactory,
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery,
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsUpdateCommand: DynamicsUpdateCommand,
    private readonly dynamicsDeleteRecordCommand: DynamicsDeleteRecordCommand
  ) {
  }

  async getJobs(): Promise<Jobs> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      orderBy: ['createdon desc']
    }
    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsJob>(request);
    return this.dynamicsJobFactory.createJobs(response);
  }

  async getJob(jobID: JobID): Promise<Job> {
    const request: RetrieveRequest = {
      collection: this.tableName,
      key: jobID.getValue()
    }

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsJob>(request);
    return this.dynamicsJobFactory.createJob(response);
  }

  async createJob(jobOptions: Partial<JobOptions>): Promise<Job> {
    const data = this.dynamicsJobFactory.getDynamicFields(jobOptions);
    const createRequest: CreateRequest = {
      collection: this.tableName,
      data,
      returnRepresentation: true
    }

    const response = await this.dynamicsCreateCommand.execute<DynamicsJob>(createRequest);
    return this.dynamicsJobFactory.createJob(response);
  }

  async updateJob(jobOptions: JobOptions): Promise<Job> {
    const request: UpdateRequest = {
      collection: this.tableName,
      key: jobOptions.id.getValue(),
      data: this.dynamicsJobFactory.getDynamicFields(jobOptions),
      returnRepresentation: true
    }

    const response = await this.dynamicsUpdateCommand.execute<DynamicsJob>(request);
    return this.dynamicsJobFactory.createJob(response);
  }

  deleteJob(jobID: JobID): Promise<boolean> {
    const request: DeleteRequest = {
      collection: this.tableName,
      key: jobID.getValue()
    }
    return this.dynamicsDeleteRecordCommand.execute(request);
  }
}
