import {Inject, Injectable} from "@angular/core";
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";
import {BusinessUnitRepository} from "@modules/business-unit/Domain/BusinessUnit/Repository/business-unit-repository";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  DynamicsBusinessUnitFactory
} from "@modules/business-unit/Infrastructure/Repository/BusinessUnit/Factory/dynamics-business-unit-factory";
import {
  DynamicsBusinessUnitModel
} from "@modules/business-unit/Infrastructure/Repository/BusinessUnit/Model/dynamics-business-unit-model";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {RetrieveMultipleRequest, RetrieveRequest} from "dynamics-web-api";

@Injectable({'providedIn': 'root'})
export class DynamicsBusinessUnitRepository extends BusinessUnitRepository {

  private readonly table = 'businessunits';
  private readonly fields: (keyof DynamicsBusinessUnitModel)[] = [
    'businessunitid',
    'name',
    'websiteurl',
    'emailaddress',
    'address1_telephone1',
    '_parentbusinessunitid_value',
  ];

  constructor(
    private readonly dynamicsBusinessUnitFactory: DynamicsBusinessUnitFactory,
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery,
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    @Inject(RootBusinessUnit) private readonly rootBusinessUnit: string
  ) {
    super();
  }

  async getByID(id: BusinessUnitID): Promise<BusinessUnit> {
    const request: RetrieveRequest = {
      collection: this.table,
      key: id.getValue(),
      select: this.fields
    };
    const response = await this.dynamicsRetrieveQuery.execute<DynamicsBusinessUnitModel>(request);
    return this.dynamicsBusinessUnitFactory.execute(response);
  }

  async getAll(): Promise<BusinessUnits> {
    const request: RetrieveMultipleRequest = {
      collection: this.table,
      select: this.fields,
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsBusinessUnitModel>(request);
    const models = response.value.map(data => this.dynamicsBusinessUnitFactory.execute(data));
    const allUnits = new BusinessUnits(models);
    return allUnits.getOnlyChildrenOrDirectParents(BusinessUnitID.fromString(this.rootBusinessUnit));
  }
}
