import {Injectable} from "@angular/core";
import {EstimateRepository} from "@modules/estimate/Domain/EstimateOption/Repository/estimate-repository";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";

@Injectable({
  providedIn: 'root'
})
export class DeleteEstimateUseCase {
  constructor(
    private estimateRepository: EstimateRepository
  ) {
  }

  async execute(estimateID: EstimateID): Promise<void> {
    return this.estimateRepository.deleteEstimate(estimateID);
  }
}
