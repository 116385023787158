import {Injectable} from "@angular/core";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationRepository} from "@modules/calculation/Domain/Calculation/Repository/calculation-repository";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";

@Injectable({
  providedIn: 'root'
})
export class GetCalculationUseCase {
  constructor(
    private readonly calculationRepository: CalculationRepository,
  ) {
  }

  async execute<TStrategy extends CalculationStrategy>(calculationID: CalculationID): Promise<Calculation<TStrategy>> {
    return await this.calculationRepository.getCalculationByID<TStrategy>(calculationID);
  }
}
