import {Injectable} from '@angular/core';
import {MsalService} from "@azure/msal-angular";
import {UserLogin} from "../../Domain/user-login";

@Injectable({
  providedIn: 'root'
})
export class MsAlUserLogin implements UserLogin {
  constructor(
    private readonly authService: MsalService,
  ) {
  }

  execute(): void {
    this.authService.loginRedirect();
  }
}
