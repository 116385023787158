import {Injectable} from "@angular/core";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {
  EstimateCalculationSummary
} from "@modules/estimate-calculation-summary/Domain/EstimateCalculationSummary/estimate-calculation-summary";
import {
  EstimateCalculationSummaryNotFoundException
} from "@modules/estimate-calculation-summary/Domain/EstimateCalculationSummary/Excetion/estimate-calculation-summary-not-found-exception";
import {
  EstimateCalculationSummaryRepository
} from "@modules/estimate-calculation-summary/Domain/EstimateCalculationSummary/Repository/estimate-calculation-summary-repository";
import {
  DynamicsEstimateCalculationSummaryMapper
} from "@modules/estimate-calculation-summary/Infrastructure/Repository/EstimateCalculationSummary/Mapper/dynamics-estimate-summary-mapper.service";
import {
  DynamicsEstimateCalculationSummary
} from "@modules/estimate-calculation-summary/Infrastructure/Repository/EstimateCalculationSummary/Model/dynamics-estimate-calculation-summary";
import {
  DynamicsCreateCommand
} from "@modules/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {CreateRequest, DeleteRequest, RetrieveMultipleRequest} from "dynamics-web-api";

@Injectable({providedIn: 'root'})
export class DynamicsEstimateCalculationSummaryRepository extends EstimateCalculationSummaryRepository {

  private readonly table = 'cr9b4_ifoamicalcestimatecalculationsumamries';
  private readonly estimateRelationName = "cr9b4_Calculation";
  private readonly estimateTableName = "cr9b4_ifoamestimatecalculations";
  private readonly calculationRelationName = "cr9b4_Calculation";

  constructor(
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsDeleteCommand: DynamicsDeleteRecordCommand,
    private readonly mapper: DynamicsEstimateCalculationSummaryMapper
  ) {
    super();
  }

  async getByCalculationID(calculationID: CalculationID): Promise<EstimateCalculationSummary> {
    const request: RetrieveMultipleRequest = {
      collection: this.table,
      queryParams: [`$filter=_cr9b4_calculation_value eq ${calculationID.getValue()}`],
      expand: [
        {
          property: this.calculationRelationName,
          select: ['cr9b4_calculationtype']
        }
      ]
    }

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsEstimateCalculationSummary>(request);
    if (response.value.length === 0) throw new EstimateCalculationSummaryNotFoundException(calculationID);
    if (response.value.length > 1) throw new Error('Multiple Estimate Calculation Summary found');

    return this.mapper.mapTo(response.value[0]);
  }

  async save(calculationID: CalculationID, estimateCalculationSummary: EstimateCalculationSummary): Promise<void> {
    const mappedData = await this.mapper.mapFrom(estimateCalculationSummary);
    delete mappedData._cr9b4_calculation_value;
    delete mappedData.cr9b4_Calculation;

    const associateKey = `${this.estimateRelationName}@odata.bind`;
    const associateValue = `/${this.estimateTableName}(${calculationID.getValue()})`;
    const data = {
      ...mappedData,
      [associateKey]: associateValue,
    }

    const request: CreateRequest = {
      collection: this.table,
      data
    }

    return this.dynamicsCreateCommand.execute<void>(request);
  }

  async delete(calculationID: CalculationID): Promise<void> {
    const idRequest: RetrieveMultipleRequest = {
      collection: this.table,
      queryParams: [`$filter=_cr9b4_calculation_value eq ${calculationID.getValue()}`]
    }

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsEstimateCalculationSummary>(idRequest);
    const ids = response.value.map(value => value.cr9b4_ifoamicalcestimatecalculationsumamryid);

    await Promise.all(ids.map(id => {
      const request: DeleteRequest = {
        collection: this.table,
        key: id as string
      }
      return this.dynamicsDeleteCommand.execute(request);
    }));
  }
}
