import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {ActionSheetButton, ActionSheetController, ActionSheetOptions} from "@ionic/angular";
import {MenuEntry} from "../../Domain/menu-entry";

@Component({
  selector: 'app-tree-dot-menu',
  templateUrl: './tree-dot-menu.component.html',
  styleUrls: ['./tree-dot-menu.component.scss'],
})
export class TreeDotMenuComponent implements OnChanges, OnDestroy {
  @Input() menuEntries: MenuEntry[] | undefined;
  protected buttonID: string = Math.random().toString(36).substring(7);
  protected loading: boolean = false;
  private currentActionSheet: HTMLIonActionSheetElement | undefined;

  constructor(
    private actionSheetCtrl: ActionSheetController
  ) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['menuEntries']) {
      await this.currentActionSheet?.dismiss();
    }
  }

  ngOnDestroy(): void {
    this.currentActionSheet?.dismiss();
  }

  async openMenu(): Promise<void> {
    if (!this.menuEntries) return;

    const buttons: ActionSheetButton[] = this.menuEntries.map(menuEntry => {
      return {
        text: menuEntry.title,
        icon: menuEntry.icon,
        handler: () => {
          const promise = menuEntry.action();
          if (promise) {
            this.loading = true;
            promise.finally(() => this.loading = false);
          }
        }
      }
    });
    buttons.push({
      text: 'Cancel',
      role: 'cancel'
    });

    const options: ActionSheetOptions = {
      buttons: buttons,
    };

    this.currentActionSheet = await this.actionSheetCtrl.create(options);
    await this.currentActionSheet.present();
  }
}
