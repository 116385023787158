import {Injectable} from '@angular/core';
import {MsalService} from "@azure/msal-angular";
import {UserLogout} from "../../Domain/user-logout";

@Injectable({
  providedIn: 'root'
})
export class MsAlUserLogout implements UserLogout {

  constructor(
    private readonly authService: MsalService,
  ) {
  }

  execute(): void {
    this.authService.logout();
  }
}
