import EnvironmentInterface from "./environment-interface";

export const baseEnvironment: Partial<EnvironmentInterface> = {
  build: {
    version: import.meta.env.NG_APP_BUILD_VERSION,
    date: import.meta.env.NG_APP_BUILD_DATE
  },
  sentryDsn: import.meta.env.NG_APP_SENTRY_DSN,
  glitchTipDsn: import.meta.env.NG_APP_GLITCH_TIP_DSN,
  productPricesTable: import.meta.env.NG_APP_PRODUCT_PRICES_TABLE,
  serviceTitanProxyConfig: {
    baseUrl: import.meta.env.NG_APP_SERVICE_TITAN_PROXY_BASE_URL
  },
  msalConfig: {
    auth: {
      clientId: import.meta.env.NG_APP_MS_CLIENT_ID,
      authority: 'https://login.microsoftonline.com/' + import.meta.env.NG_APP_MS_TENANT_ID,
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
  },
  dynamicsConfig: {
    baseUrl: import.meta.env.NG_APP_MS_DYNAMICS_BASE_URL,
    cacheTimeToLive: import.meta.env.NG_APP_MS_DYNAMICS_CACHE_TIME_TO_LIVE,
    businessUnitRootID: import.meta.env.NG_APP_MS_DYNAMICS_BUSINESS_UNIT_ROOT_ID,
  },
  apiConfig: {
    scopes: [
      "User.Read",
      import.meta.env.NG_APP_MS_DYNAMICS_BASE_URL + "/user_impersonation"
    ],
    uri: 'https://graph.microsoft.com/v1.0/me'
  },
  support: {
    email: {
      receiver: import.meta.env.NG_APP_SUPPORT_EMAIL_RECEIVER || ''
    }
  }
};
