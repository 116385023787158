import {Injectable} from '@angular/core';
import {Estimate} from "@modules/estimate/Domain/EstimateOption/estimate";
import {EstimateRepository} from "@modules/estimate/Domain/EstimateOption/Repository/estimate-repository";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";

@Injectable({
  providedIn: 'root'
})
export class GetEstimateByIdUseCase {
  constructor(
    private estimateRepository: EstimateRepository
  ) {
  }

  async execute(estimateID: EstimateID): Promise<Estimate> {
    return this.estimateRepository.getEstimateByID(estimateID);
  }
}
