import {Injectable} from "@angular/core";
import {ESTIMATE_SUMMARY_TOTAL_TAB_ID} from "@modules/estimate-summary/Domain/EstimateSummaryTab/estimate-summary-tab";

@Injectable({
  providedIn: 'root'
})
export class EstimateSummaryLinkProvider {

  public static BASE_URL = 'estimate-summary';

  public static getEstimateSummaryRouterLink(estimateID: string): string[] {
    return [
      ...EstimateSummaryLinkProvider.getBaseRouterLink(),
      estimateID,
      ESTIMATE_SUMMARY_TOTAL_TAB_ID.getValue()
    ];
  }

  private static getBaseRouterLink(): string[] {
    return ['/', EstimateSummaryLinkProvider.BASE_URL];
  }
}
