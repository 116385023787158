import {Injectable} from "@angular/core";
import {GmInputsFactory} from "@modules/gm-inputs/Application/Factory/gm-inputs-factory";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {
  DynamicsGmInputs
} from "@modules/gm-inputs/Infrastructure/Repository/DynamicsGmInputsRepository/Type/dynamics-gm-inputs";

@Injectable({
  providedIn: 'root'
})
export class DynamicsGmInputsFactory {

  private readonly defaultValues = {
    crews: [],
    benefitsRate: 0,
    salesTaxRate: 0,
    overagePercentage: 0,
    royaltyRate: 0,
    commissionRate: 0,
    ccPercent: 0,
    financingChargesPercent: 0
  }

  constructor(
    private readonly gmInputsFactory: GmInputsFactory
  ) {
  }

  create(data: DynamicsGmInputs): GmInputs {
    const receivedInputs = JSON.parse(data.cr9b4_inputs);
    const inputs = {...this.defaultValues, ...receivedInputs};

    return this.gmInputsFactory.create(
      data.cr9b4_ifoamcalculatorglobalinputsid,
      data.cr9b4_title,
      inputs.crews,
      inputs.benefitsRate,
      inputs.salesTaxRate,
      inputs.overagePercentage,
      inputs.royaltyRate,
      inputs.commissionRate,
      inputs.ccPercent
    );
  }

  getDynamicFields(gmInputs: Partial<GmInputs>): Partial<DynamicsGmInputs> {
    if (!gmInputs.getBenefitsRatePercentage) throw new Error('Benefits rate is required');
    if (!gmInputs.getSalesTaxRatePercentage) throw new Error('Sales tax rate is required');
    if (!gmInputs.getOveragePercentage) throw new Error('Overage percentage is required');
    if (!gmInputs.getRoyaltyRatePercentage) throw new Error('Royalty rate is required');
    if (!gmInputs.getCommissionRatePercentage) throw new Error('Commission rate is required');
    if (!gmInputs.getCCPercent) throw new Error('Credit Card fee is required');
    if (!gmInputs.getCrews) throw new Error('Crews are required');

    const inputs = {
      benefitsRate: gmInputs.getBenefitsRatePercentage(),
      salesTaxRate: gmInputs.getSalesTaxRatePercentage(),
      overagePercentage: gmInputs.getOveragePercentage(),
      royaltyRate: gmInputs.getRoyaltyRatePercentage(),
      commissionRate: gmInputs.getCommissionRatePercentage(),
      ccPercent: gmInputs.getCCPercent(),
      crews: gmInputs.getCrews().value
    };

    return {
      cr9b4_inputs: JSON.stringify(inputs),
    }
  }

}
