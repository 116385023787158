import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmDialogService} from "@modules/_shared/Service/ConfirmDialog/confirm-dialog.service";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  UpdateProductOverriddenPrice
} from "@modules/product/Application/UseCase/Command/update-product-overridden-price.service";
import {Product} from "@modules/product/Domain/Product/product";
import {Price} from "@modules/product/Domain/Product/VO/price";
import {ProductOverriddenPrice} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-price";

@Component({
  selector: 'app-products-batch-mode-editor',
  templateUrl: './products-batch-mode-editor.component.html',
  styleUrls: ['./products-batch-mode-editor.component.scss'],
})
export class ProductsBatchModeEditorComponent implements OnInit {
  @Input({required: true}) products: Product[] = [];
  @Input({required: true}) businessUnitID?: string;
  @Output() pricesUpdated = new EventEmitter<ProductOverriddenPrice[] | undefined>();

  protected loading = false;

  protected selectedProducts: Product[] = [];
  protected form: FormGroup = new FormGroup({
    overriddenPrice: new FormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.pattern(/^\d+(\.\d{1,2})?$/),
    ]),
  });

  constructor(
    private readonly confirmDialogService: ConfirmDialogService,
    private readonly notificationService: NotificationService,
    private readonly updateProductOverriddenPrice: UpdateProductOverriddenPrice,
  ) {
  }

  ngOnInit() {
    this.selectedProducts = [];
  }

  toggleProduct(product: Product, $event: MouseEvent) {
    this.selectedProducts = this.selectedProducts.includes(product) ?
      this.selectedProducts.filter(p => p !== product) :
      this.selectedProducts.concat(product);

    $event.stopPropagation();
  }

  async overridePrice() {
    if (this.form.invalid) {
      return;
    }

    if (!this.selectedProducts.length) {
      return;
    }

    const value = parseFloat(this.form.value.overriddenPrice);
    const newPrice = new Price(value);

    const message = `
    Are you sure you want to override the price for ${this.selectedProducts.length} products to $${newPrice.value}?
    This action cannot be undone.
    `;

    const confirmed = await this.confirmDialogService.confirm(message, 'Override Price', 'destructive');
    if (!confirmed) return;

    this.loading = true;

    try {
      const newPrices = await this.updateProductsPrice(newPrice);
      this.pricesUpdated.emit(newPrices);
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }

  private async updateProductsPrice(newPrice: Price): Promise<ProductOverriddenPrice[]> {
    if (!this.businessUnitID) {
      throw new Error('Business unit ID is required');
    }

    const businessUnitID = BusinessUnitID.fromString(this.businessUnitID);
    return await Promise.all(this.selectedProducts.map(async product => {
      return this.updateProductOverriddenPrice.execute(
        product.getOverriddenPrice().setPrice(newPrice),
        businessUnitID,
      );
    }));
  }
}
