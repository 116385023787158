import {Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {UpdateService} from "@modules/_shared/Service/UpdateService/update-service";
import {environment} from "@modules/environments/environment";
import {EnvironmentType} from "@modules/environments/environment-type";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly router: Router,
    private readonly updateService: UpdateService
  ) {
    if (environment.environment !== EnvironmentType.production) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const gtag = window['gtag'];
    if (!gtag) return;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_title: this.router.routerState.snapshot.url,
          page_path: event.urlAfterRedirects,
          page_location: location.origin
        })
      }
    });
  }
}
