import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {
  CanAccessBusinessUnitAuthGuard
} from "@modules/business-unit/Infrastructure/Guard/can-access-business-unit-auth-guard";
import {ProductEditorComponent} from "@modules/product/Presentation/product-editor/product-editor.component";
import {ProductListComponent} from "@modules/product/Presentation/product-list/product-list.component";
import {
  ProductsTypeSelectorComponent
} from "@modules/product/Presentation/products-type-selector/products-type-selector.component";


const routes: Routes = [
  {
    path: 'categories/:businessUnitID',
    component: ProductsTypeSelectorComponent,
    canActivate: [CanAccessBusinessUnitAuthGuard]
  },
  {
    path: 'category/:businessUnitID/:productTypeID',
    component: ProductListComponent,
    canActivate: [CanAccessBusinessUnitAuthGuard]
  },
  {
    path: 'product-edit/:businessUnitID/:productTypeID/:productID',
    component: ProductEditorComponent,
    canActivate: [CanAccessBusinessUnitAuthGuard]
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class ProductsRoutingModule {
}
