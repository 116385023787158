import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, GuardResult, Router} from "@angular/router";
import {
  GetBusinessUnitsUseCase
} from "@modules/business-unit/Application/UseCase/Query/get-business-units-use-case.service";
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";
import {Profile} from "@modules/profile/Domain/Profile/profile";

@Injectable({providedIn: 'root'})
export class CanAccessBusinessUnitAuthGuard implements CanActivate {
  private cachedUserProfile: Profile | null = null;
  private cachedBusinessUnits: BusinessUnits | null = null;

  constructor(
    private readonly getCurrentUserProfileUseCase: GetCurrentUserProfileUseCase,
    private readonly getBusinessUnitsUseCase: GetBusinessUnitsUseCase,
    private readonly router: Router
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<GuardResult> {
    const businessUnitID = route.paramMap.get('businessUnitID');
    if (!businessUnitID) {
      await this.router.navigate(['/access-denied']);
      return false;
    }

    const id = BusinessUnitID.fromString(businessUnitID);
    const hasAccess = await this.checkAccess(id);

    if (!hasAccess) {
      await this.router.navigate(['/access-denied']);
    }

    return hasAccess;
  }

  private async checkAccess(businessUnitID: BusinessUnitID): Promise<boolean> {
    const profile = await this.getUserProfile();
    const businessUnits = await this.getBusinessUnits();

    const availableBusinessUnits = businessUnits.getUserAvailableBusinessUnits(profile);
    return availableBusinessUnits.isExists(businessUnitID);
  }

  private async getUserProfile(): Promise<Profile> {
    if (!this.cachedUserProfile) {
      this.cachedUserProfile = await this.getCurrentUserProfileUseCase.execute();
    }
    return this.cachedUserProfile;
  }

  private async getBusinessUnits(): Promise<BusinessUnits> {
    if (!this.cachedBusinessUnits) {
      this.cachedBusinessUnits = await this.getBusinessUnitsUseCase.execute();
    }
    return this.cachedBusinessUnits;
  }
}
