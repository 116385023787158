import {Injectable} from "@angular/core";
import {Crews} from "@modules/gm-inputs/Domain/Crew/crews";
import {Crew} from "@modules/gm-inputs/Domain/Crew/VO/crew";

export interface CrewInput {
  name: string;
  rate: number;

}

@Injectable({
  providedIn: 'root'
})
export class CrewsFactory {
  createCrews(data: CrewInput[]): Crews {
    return new Crews(data.map(crew => this.createCrew(crew)));
  }

  createCrew(data: CrewInput): Crew {
    return new Crew(data.name, data.rate);
  }
}
