import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import {EstimateOptions} from "./estimate-options";

export class Estimate {
  readonly id: EstimateID;
  name: string;
  readonly jobID: string;
  readonly createdOn: Date;
  readonly createdBy: string;
  estimateDollar: number | null;
  marginPercent: number | null;
  discountEnabled: boolean;
  private discount: number;

  constructor(options: EstimateOptions) {
    if (!options.id) throw new Error("Estimate ID is required");
    if (!options.name) throw new Error("Estimate Name is required");
    if (!options.jobID) throw new Error("Job ID is required");
    if (!options.createdOn) throw new Error("Created On is required");
    if (!options.createdBy) throw new Error("Created By is required");

    this.id = options.id;
    this.name = options.name;
    this.jobID = options.jobID;
    this.createdOn = options.createdOn;
    this.createdBy = options.createdBy;
    this.estimateDollar = options.estimateDollar || null;
    this.marginPercent = options.marginPercent || null;
    this.discount = options.discount;
    this.discountEnabled = options.discountEnabled;
  }

  getDiscount(): number {
    return this.discount
  }

  setDiscount(discount: number) {
    this.discount = discount;
  }
}
