import {Injectable} from "@angular/core";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationRepository} from "@modules/calculation/Domain/Calculation/Repository/calculation-repository";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import {
  DynamicsCreateCommand
} from "@modules/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {CreateRequest, DeleteRequest, RetrieveMultipleRequest, RetrieveRequest} from "dynamics-web-api";
import {DynamicsCalculationFactory} from "./Factory/dynamics-calculation-factory.service";
import {DynamicsEstimateCalculation} from "./Type/dynamics-estimate-calculation";

@Injectable({
  providedIn: 'root'
})
export class DynamicsEstimateCalculationRepository extends CalculationRepository {

  public static readonly tableName = "cr9b4_ifoamestimatecalculations";
  private readonly estimateIDFieldName = "_cr9b4_estimateid_value";
  private readonly estimateRelationName = "cr9b4_EstimateID";
  private readonly estimateTableName = "cr9b4_ifoamestimates";

  constructor(
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery,
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsDeleteRecordCommand: DynamicsDeleteRecordCommand,
    private readonly dynamicsEstimateCalculationFactory: DynamicsCalculationFactory
  ) {
    super();
  }

  async addCalculationToEstimate(estimateCalculation: Partial<Calculation>): Promise<Calculation> {
    if (!estimateCalculation.estimateID) throw new Error('Estimate ID is required');
    if (!estimateCalculation.strategyID) throw new Error('Calculator name is required');

    const mappedData = this.dynamicsEstimateCalculationFactory.createDynamicsEstimateCalculation(estimateCalculation);

    const associateKey = `${this.estimateRelationName}@odata.bind`;
    const associateValue = `/${this.estimateTableName}(${estimateCalculation.estimateID})`;
    const data = {
      ...mappedData,
      [associateKey]: associateValue,
    }

    const createRequest: CreateRequest = {
      collection: DynamicsEstimateCalculationRepository.tableName,
      data,
      returnRepresentation: true,
    };

    const response = await this.dynamicsCreateCommand.execute<DynamicsEstimateCalculation>(createRequest);
    return this.dynamicsEstimateCalculationFactory.createCalculation(response);
  }

  deleteCalculationFromEstimate(calculationID: CalculationID): Promise<void> {
    const request: DeleteRequest = {
      collection: DynamicsEstimateCalculationRepository.tableName,
      key: calculationID.getValue(),
    }

    return this.dynamicsDeleteRecordCommand.execute(request);
  }

  async getCalculationByID<TStrategy extends CalculationStrategy>(estimateID: CalculationID): Promise<Calculation<TStrategy>> {
    if (!estimateID) throw new Error('Estimate ID is required');

    const request: RetrieveRequest = {
      collection: DynamicsEstimateCalculationRepository.tableName,
      key: estimateID.getValue(),
    }

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsEstimateCalculation>(request);
    return this.dynamicsEstimateCalculationFactory.createCalculation<TStrategy>(response);
  }

  async getCalculationsByEstimateID(estimateID: EstimateID): Promise<Calculation[]> {
    const request: RetrieveMultipleRequest = {
      collection: DynamicsEstimateCalculationRepository.tableName,
      filter: `${this.estimateIDFieldName} eq ${estimateID}`,
    }

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsEstimateCalculation>(request);
    return this.dynamicsEstimateCalculationFactory.createCalculations(response);
  }
}
