export class JobContactInformation {
  readonly customerName: string;
  readonly serviceAddress: string | undefined;
  readonly phoneNumber: string | undefined;
  readonly email: string | undefined;

  constructor(
    customerName: string,
    serviceAddress: string | undefined,
    phoneNumber: string | undefined,
    email: string | undefined
  ) {
    if (!customerName) throw new Error("Customer Name is required");

    this.customerName = customerName;
    this.serviceAddress = serviceAddress;
    this.phoneNumber = phoneNumber;
    this.email = email;
  }
}
