import {Product} from "@modules/product/Domain/Product/product";

export class ProductLinkProvider {
  static BASE_URL: string = 'product';


  static getBaseRoute(): string[] {
    return ['/', ProductLinkProvider.BASE_URL];
  }

  static getCategoriesBaseRoute(): string[] {
    return [...ProductLinkProvider.getBaseRoute(), 'categories'];
  }

  static getCategoriesRoute(businessUnitID: string): string[] {
    return [...ProductLinkProvider.getBaseRoute(), 'categories', businessUnitID];
  }

  static getCategoryRoute(
    businessUnitID: string,
    productTypeID: string
  ): string[] {
    return [...ProductLinkProvider.getBaseRoute(), 'category', businessUnitID, productTypeID];
  }

  static getProductEditRoute(product: Product, businessUnitID: string): string[] {
    return [
      ...ProductLinkProvider.getBaseRoute(),
      'product-edit',
      businessUnitID,
      product.productType.id,
      product.id
    ];
  }
}
