import {Injectable} from "@angular/core";
import {
  GetGmInputsByBusinessUnitIDUseCase
} from "@modules/gm-inputs/Application/UseCase/Query/get-gm-inputs-by-buid-use-case";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";

@Injectable({'providedIn': 'root'})
export class GetCurrentUserGmInputs {
  constructor(
    private readonly getProfile: GetCurrentUserProfileUseCase,
    private readonly getGmInputsByBusinessUnitIDUseCase: GetGmInputsByBusinessUnitIDUseCase,
  ) {
  }

  async execute(): Promise<GmInputs> {
    const profile = await this.getProfile.execute();
    return await this.getGmInputsByBusinessUnitIDUseCase.execute(profile.businessUnitID);
  }
}
