<ion-progress-bar *ngIf="loading; else formTemplate" type="indeterminate"></ion-progress-bar>

<ng-template #formTemplate>
  <div *ngIf="gmInputs" [formGroup]="form">
    <ion-item>
      <ion-select
        formControlName="crewSize"
        label="Select Labor Crew"
        label-placement="floating"
        placeholder="Select Labor Crew"
      >
        <ion-select-option *ngFor="let crew of gmInputs.getCrews().value; let i = index" [value]="i+1">
          {{ getCrewName(i + 1) }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </div>
</ng-template>
