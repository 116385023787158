import {Injectable} from "@angular/core";
import {MissingRequiredFieldsException} from "@modules/_shared/Service/Validator/missing-required-fields.exception";
import {RequiredFieldsValidator} from "@modules/_shared/Service/Validator/required-fields-validator.service";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import {
  DynamicsEstimate
} from "@modules/estimate/Infrastructure/Repository/DynamicsEstimateRepository/Model/dynamics-estimate";
import {RetrieveMultipleResponse} from "dynamics-web-api";
import {EstimateFactory} from "../../../../Application/Factory/estimate-factory";
import {EstimatesFactory} from "../../../../Application/Factory/estimates-factory";
import {Estimates} from "../../../../Domain/EstimateOption/Aggregate/estimates";
import {Estimate} from "../../../../Domain/EstimateOption/estimate";
import {EstimateOptions} from "../../../../Domain/EstimateOption/estimate-options";

@Injectable({
  providedIn: 'root'
})
export default class DynamicsEstimateFactory {
  private readonly requiredFields: (keyof DynamicsEstimate)[] = ["_cr9b4_jobid_value", "cr9b4_name", "cr9b4_ifoamestimateid"];

  constructor(
    private readonly estimatesFactory: EstimatesFactory,
    private readonly estimateFactory: EstimateFactory,
    private readonly requiredValidator: RequiredFieldsValidator
  ) {
  }

  createEstimates(response: RetrieveMultipleResponse<DynamicsEstimate>): Estimates {
    const jobID = this.getJobID(response);

    const estimateOptions: EstimateOptions[] = [];
    for (const estimate of response.value) {
      try {
        this.requiredValidator.validate(estimate, this.requiredFields);
        const estimateOption = this.createEstimateOption(estimate);
        estimateOptions.push(estimateOption);
      } catch (e: MissingRequiredFieldsException | unknown) {
        if (e instanceof MissingRequiredFieldsException) {
          console.error(`Estimate with id ${estimate.cr9b4_ifoamestimateid}: ${e.message}`);
        }
      }
    }

    return this.estimatesFactory.createEstimates(jobID, estimateOptions);
  }

  createEstimate(response: DynamicsEstimate): Estimate {
    this.requiredValidator.validate(response, this.requiredFields);
    const estimateOption = this.createEstimateOption(response);
    return this.estimateFactory.create(estimateOption);
  }

  createDynamicsEstimateFields(estimate: Partial<Estimate>): Partial<DynamicsEstimate> {
    if (!estimate.jobID) throw new Error("jobID is required");
    if (!estimate.name) throw new Error("name is required");

    return {
      cr9b4_name: estimate.name,
      //cr9b4_servicetitanjobid: estimate.jobID,
    };
  }

  getDynamicFields(estimate: Partial<EstimateOptions>): Partial<DynamicsEstimate> {
    const dynamicsEstimate: Partial<DynamicsEstimate> = {};

    if (estimate.name) {
      dynamicsEstimate.cr9b4_name = estimate.name;
    }

    if (estimate.estimateDollar) {
      dynamicsEstimate.cr9b4_estimatedollar = estimate.estimateDollar;
    }

    if (estimate.marginPercent) {
      dynamicsEstimate.cr9b4_estimatemarginpercent = estimate.marginPercent;
    }

    if (estimate.discount) {
      dynamicsEstimate.cr9b4_discount = estimate.discount;
    }

    if (estimate.discountEnabled !== undefined) {
      dynamicsEstimate.cr9b4_isdiscountenabled = estimate.discountEnabled;
    }

    return dynamicsEstimate;
  }

  private getJobID(response: RetrieveMultipleResponse<DynamicsEstimate>) {
    const jobIDs = response.value.map(v => v._cr9b4_jobid_value);
    const uniqueJobIDs = [...new Set(jobIDs)];

    if (uniqueJobIDs.length > 1) {
      throw new Error("Estimates must belong to the same edit-job, but they belong to different job-list: " + uniqueJobIDs.join(", "));
    }

    return uniqueJobIDs[0];
  }

  private createEstimateOption(estimate: DynamicsEstimate): EstimateOptions {
    return {
      id: EstimateID.fromString(estimate.cr9b4_ifoamestimateid),
      createdBy: estimate._createdby_value,
      createdOn: new Date(estimate.createdon),
      name: estimate.cr9b4_name,
      jobID: estimate._cr9b4_jobid_value,
      estimateDollar: estimate.cr9b4_estimatedollar,
      marginPercent: estimate.cr9b4_estimatemarginpercent,
      discount: estimate.cr9b4_discount,
      discountEnabled: estimate.cr9b4_isdiscountenabled,
    };
  }
}
