import {Injectable} from '@angular/core';
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {map, Observable} from "rxjs";
import {AuthStateObservable} from "../../Domain/auth-state-observable";

@Injectable({
  providedIn: 'root'
})
export class MsAlAuthState implements AuthStateObservable {
  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService
  ) {
  }

  $execute(): Observable<boolean> {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      return new Observable<boolean>(subscriber => {
        subscriber.next(true);
        subscriber.complete();
      });
    }

    return this.msalBroadcastService.msalSubject$
      .pipe(
        map(
          (): boolean => {
            return this.msalService.instance.getAllAccounts().length > 0
          }
        )
      );
  }
}
