import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {RoofPitch} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/roof-pitch";
import {RoofPitches} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/roof-pitches";
import {RoofPitchName} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/ValueObject/roof-pitch-name";

@Injectable({
  providedIn: 'root'
})
export class GetRoofPitchesUseCase implements UseCase<void, RoofPitches> {

  private roofPitches = [
    {name: '1/12', roofPitchFactor: 1.0035, valleyHipFactor: 1.4167},
    {name: '2/12', roofPitchFactor: 1.0138, valleyHipFactor: 1.424},
    {name: '3/12', roofPitchFactor: 1.0308, valleyHipFactor: 1.4362},
    {name: '4/12', roofPitchFactor: 1.0541, valleyHipFactor: 1.453},
    {name: '5/12', roofPitchFactor: 1.0833, valleyHipFactor: 1.4743},
    {name: '6/12', roofPitchFactor: 1.118, valleyHipFactor: 1.5},
    {name: '7/12', roofPitchFactor: 1.1577, valleyHipFactor: 1.5298},
    {name: '8/12', roofPitchFactor: 1.2019, valleyHipFactor: 1.5635},
    {name: '9/12', roofPitchFactor: 1.25, valleyHipFactor: 1.6008},
    {name: '10/12', roofPitchFactor: 1.3017, valleyHipFactor: 1.6415},
    {name: '11/12', roofPitchFactor: 1.3566, valleyHipFactor: 1.6853},
    {name: '12/12', roofPitchFactor: 1.4142, valleyHipFactor: 1.732},
  ];

  public execute(): Promise<RoofPitches> {
    const pitches = this.roofPitches.map(row => new RoofPitch(
        RoofPitchName.fromString(row.name),
        row.valleyHipFactor,
        row.roofPitchFactor
      )
    );
    return Promise.resolve(new RoofPitches(pitches));
  }
}
