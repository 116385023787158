import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {CalculatorTabOptions} from "./calculator-tab-options";

export class CalculatorTab {
  readonly id: string;
  readonly calculationID: CalculationID;
  name: string;
  state: unknown;

  constructor(data: CalculatorTabOptions) {
    if (!data.id) throw new Error('CalculatorTab must have an id');
    if (!data.calculationID) throw new Error('CalculatorTab must have a calculationID');
    if (!data.name) throw new Error('CalculatorTab must have a name');
    if (!data.state) throw new Error('CalculatorTab must have a state');

    this.id = data.id;
    this.calculationID = data.calculationID;
    this.name = data.name;
    this.state = data.state;
  }
}
