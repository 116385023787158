import {NgModule} from "@angular/core";
import {MSAL_INSTANCE} from "@azure/msal-angular";
import {AreaRepository} from "@modules/area/Domain/Area/Repository/area-repository";
import StaticAreaRepository from "@modules/area/Infrastructure/Repository/static-area-repository";
import {BusinessUnitModule} from "@modules/business-unit/business-unit.module";
import {BattingCalculationModule} from "@modules/calculation-impl/batting/_calculator/batting-calculation.module";
import {BlowInCalculatorModule} from "@modules/calculation-impl/blow-in-calculator/blow-in-calculator.module";
import {FoamCalculationModule} from "@modules/calculation-impl/foam/foam-calculation.module";
import {
  MiscellaneousCalculatorModule
} from "@modules/calculation-impl/miscellaneous-calculator/miscellaneous-calculator.module";
import {RemovalCalculatorModule} from "@modules/calculation-impl/removal-calculator/removal-calculator.module";
import {CalculationStrategyModule} from "@modules/calculation-strategy/calculation-strategy.module";
import {
  CalculationStrategyRepository
} from "@modules/calculation-strategy/Domain/CalculationStrategy/Repository/calculation-strategy-repository";
import {
  InMemoryCalculationStrategyRepository
} from "@modules/calculation-strategy/Infrastructure/Repository/CalculationStrategyRepository/in-memory-calculation-strategy-repository.service";
import {
  CalculatorTabRepository
} from "@modules/calculation-tabs/Domain/CalculatorTab/Repository/calculator-tab-repository";
import {
  DynamicsCalculatorTabRepository
} from "@modules/calculation-tabs/Infrastructure/Repository/DynamicsCaltulatorTabRepository/dynamics-calculator-tab-repository";
import {CalculationRepository} from "@modules/calculation/Domain/Calculation/Repository/calculation-repository";
import {
  DynamicsEstimateCalculationRepository
} from "@modules/calculation/Infrastructure/Repository/dynamics-estimate-calculation-repository";
import {
  EstimateCalculationSummaryRepository
} from "@modules/estimate-calculation-summary/Domain/EstimateCalculationSummary/Repository/estimate-calculation-summary-repository";
import {
  DynamicsEstimateCalculationSummaryRepository
} from "@modules/estimate-calculation-summary/Infrastructure/Repository/EstimateCalculationSummary/dynamics-estimate-calculation-summary-repository.service";
import {
  EstimateSummaryRepository
} from "@modules/estimate-summary/Domain/EstimateSummary/Repository/estimate-summary-repository";
import {
  DynamicsEstimateSummaryRepository
} from "@modules/estimate-summary/Infrastructure/Repository/EstimateSummary/dynamics-estimate-summary-repository";
import {EstimateRepository} from "@modules/estimate/Domain/EstimateOption/Repository/estimate-repository";
import {
  DynamicsEstimateRepository
} from "@modules/estimate/Infrastructure/Repository/DynamicsEstimateRepository/dynamics-estimate-repository";
import {EstimateService} from "@modules/estimate/Infrastructure/Service/estimate-service";
import {GmInputsRepository} from "@modules/gm-inputs/Domain/GmInputs/Repository/gm-inputs-repository";
import {
  DynamicsGmInputsRepository
} from "@modules/gm-inputs/Infrastructure/Repository/DynamicsGmInputsRepository/dynamics-gm-inputs-repository";
import {JobRepository} from "@modules/job/Domain/Job/Repostiory/job-repository";
import {
  DynamicsJobRepository
} from "@modules/job/Infrastructure/Repository/DynamicsJobRepository/dynamics-job-repository.service";
import {MsalInstanceFactory} from "@modules/microsoft-auth/Application/Factory/msal-instance-factory";
import {MicrosoftAuthModule} from "@modules/microsoft-auth/microsoft-auth.module";
import {MicrosoftDynamicsModule} from "@modules/microsoft-dynamics/microsoft-dynamics.module";
import {ProductGroupProvider} from "@modules/product/Application/Configuration/product-types-provider.service";
import {
  BattingInsulationDynamicsProductConfiguration
} from "@modules/product/Configurations/batting-insulation-product/Application/batting-insulation-dynamics-product-configuration";
import {
  BattingInsulationProductType
} from "@modules/product/Configurations/batting-insulation-product/Application/batting-insulation-product-type";
import {
  BattingMineralWoolDynamicsProductConfiguration
} from "@modules/product/Configurations/batting-mineral-wool-product/Application/batting-mineral-wool-dynamics-product-configuration";
import {
  BattingMineralWoolProductType
} from "@modules/product/Configurations/batting-mineral-wool-product/Application/batting-mineral-wool-product-type";
import {
  BlowInBlowDynamicsProductConfiguration
} from "@modules/product/Configurations/blow-in-blow-product/Application/blow-in-blow-dynamics-product-configuration";
import {
  BlowInBlowProductType
} from "@modules/product/Configurations/blow-in-blow-product/Application/blow-in-blow-product-type";
import {
  BlowInNetDynamicsProductConfiguration
} from "@modules/product/Configurations/blow-in-net-product/Application/blow-in-net-dynamics-product-configuration";
import {
  BlowInNetProductType
} from "@modules/product/Configurations/blow-in-net-product/Application/blow-in-net-product-type";
import {
  FoamDynamicsProductConfiguration
} from "@modules/product/Configurations/foam-product/Application/foam-dynamics-product-configuration";
import {FoamProductType} from "@modules/product/Configurations/foam-product/Application/foam-product-type";
import {ProductsModule} from "@modules/product/products.module";
import {GetProfileUseCase} from "@modules/profile/Application/UseCase/get-profile-use-case.service";
import {ProfileModule} from "@modules/profile/profile.module";


@NgModule({
  imports: [
    MicrosoftAuthModule,
    MicrosoftDynamicsModule,
    CalculationStrategyModule,
    BusinessUnitModule,
    ProfileModule,
    ProductsModule,

    //Calculations
    RemovalCalculatorModule,
    BlowInCalculatorModule,
    MiscellaneousCalculatorModule,
    BattingCalculationModule,
    FoamCalculationModule,
  ],
  providers: [
    {provide: MSAL_INSTANCE, useFactory: MsalInstanceFactory},
    {provide: CalculationRepository, useExisting: DynamicsEstimateCalculationRepository},
    {provide: AreaRepository, useExisting: StaticAreaRepository},
    {provide: GmInputsRepository, useExisting: DynamicsGmInputsRepository},
    {provide: CalculatorTabRepository, useExisting: DynamicsCalculatorTabRepository},
    {provide: EstimateRepository, useExisting: DynamicsEstimateRepository},
    {provide: JobRepository, useExisting: DynamicsJobRepository},
    /**
     {provide: JobRepository, useExisting: ServiceTitanProxyJobRepository},

     // ----------------- Service Titan Start -----------------
     {
     provide: ServiceTitanProxyBaseUrl,
     useValue: environment.serviceTitanProxyConfig.baseUrl
     },
     ServiceTitanProxyJobRepository,
     {
     provide: ProxyClient,
     useFactory: proxyClientFactory,
     deps: [
     MsAlAuthServiceType,
     MsDynamicsBaseUrlType,
     ServiceTitanProxyBaseUrl,
     HttpClient
     ]
     },
     {provide: CustomerRepository, useClass: ServiceTitanProxyCustomerRepository},
     {provide: LocationRepository, useClass: ServiceTitanProxyLocationRepository},
     {provide: AppointmentRepository, useClass: ServiceTitanProxyAppointmentRepository},
     // ----------------- Service Titan End -----------------
     **/

    /**
     * FOAM
     */
    {provide: EstimateSummaryRepository, useExisting: DynamicsEstimateSummaryRepository},
    {provide: EstimateCalculationSummaryRepository, useClass: DynamicsEstimateCalculationSummaryRepository},
    {provide: CalculationStrategyRepository, useExisting: InMemoryCalculationStrategyRepository},
    InMemoryCalculationStrategyRepository,
    EstimateService,
    DynamicsEstimateRepository,
    GetProfileUseCase,
    ProductGroupProvider,
  ]
})
export class SharedModule {
  constructor(productTypeProvider: ProductGroupProvider) {
    productTypeProvider.registerProductGroup(BattingInsulationProductType, BattingInsulationDynamicsProductConfiguration);
    productTypeProvider.registerProductGroup(BattingMineralWoolProductType, BattingMineralWoolDynamicsProductConfiguration);
    productTypeProvider.registerProductGroup(BlowInBlowProductType, BlowInBlowDynamicsProductConfiguration);
    productTypeProvider.registerProductGroup(BlowInNetProductType, BlowInNetDynamicsProductConfiguration);
    productTypeProvider.registerProductGroup(FoamProductType, FoamDynamicsProductConfiguration);
  }
}
