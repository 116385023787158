import {Injectable} from "@angular/core";
import {Estimates} from "../../Domain/EstimateOption/Aggregate/estimates";
import {EstimateOptions} from "../../Domain/EstimateOption/estimate-options";
import {EstimateFactory} from "./estimate-factory";

@Injectable({
  providedIn: 'root'
})
export class EstimatesFactory {
  constructor(
    private readonly estimateFactory: EstimateFactory
  ) {
  }

  createEstimates(jobID: string, estimateOptionsList?: EstimateOptions[]): Estimates {
    const estimates = new Estimates(jobID);

    estimateOptionsList?.forEach((estimateOptions) => {
      if (estimateOptions.jobID !== jobID) {
        throw new Error("Estimate jobID must match Estimates jobID.");
      }
      const newEstimate = this.estimateFactory.create(estimateOptions);
      estimates.addEstimate(newEstimate);
    });

    return estimates;
  }
}
