import {Injectable} from "@angular/core";
import {MsalService} from "@azure/msal-angular";
import {CacheResolverService} from "@modules/_shared/Service/CacheResolver/cache-resolver.service";
import {Profile} from "@modules/profile/Domain/Profile/profile";
import {ProfileRepository} from "@modules/profile/Domain/Profile/Repository/profile-repository";

@Injectable({providedIn: 'root'})
export class GetCurrentUserProfileUseCase {
  constructor(
    private readonly profileRepository: ProfileRepository,
    private readonly msAlInstanceProvider: MsalService,
    private readonly cacheResolver: CacheResolverService
  ) {
  }

  async execute(): Promise<Profile> {
    const key = 'current-user-profile';
    const fn = async () => await this.getProfile();
    return this.cacheResolver.getOrFetch<Profile>(key, fn);
  }

  private async getProfile(): Promise<Profile> {
    const userID = this.msAlInstanceProvider.instance.getActiveAccount()?.idTokenClaims?.oid;
    if (!userID) throw new Error('User ID not found');
    return await this.profileRepository.getFromAzureUserID(userID);
  }
}
