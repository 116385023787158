import {Injectable} from "@angular/core";
import {MissingRequiredFieldsException} from "@modules/_shared/Service/Validator/missing-required-fields.exception";
import {RequiredFieldsValidator} from "@modules/_shared/Service/Validator/required-fields-validator.service";
import {JobID} from "@modules/job/Domain/Job/VO/job-id";
import {RetrieveMultipleResponse} from "dynamics-web-api";
import {JobFactory} from "../../../../Application/Factory/Job/job-factory.service";
import {JobsFactory} from "../../../../Application/Factory/Job/jobs-factory";
import {Jobs} from "../../../../Domain/Job/Aggregate/jobs";
import {CampaignEnum} from "../../../../Domain/Job/Enum/campaign-enum";
import {JobTypeEnum} from "../../../../Domain/Job/Enum/job-type-enum";
import {Job} from "../../../../Domain/Job/job";
import {JobOptions} from "../../../../Domain/Job/job-options";
import {DynamicsJob} from "../Type/dynamics-job";

@Injectable({
  providedIn: 'root'
})
export class DynamicsJobFactory {
  private readonly requiredFields: (keyof DynamicsJob)[] = ["cr9b4_ifoamjobid"];

  constructor(
    private readonly requiredValidator: RequiredFieldsValidator,
    private readonly jobsFactory: JobsFactory,
    private readonly jobFactory: JobFactory
  ) {
  }

  createJobs(response: RetrieveMultipleResponse<DynamicsJob>): Jobs {
    const jobOptions: JobOptions[] = []

    for (const job of response.value) {
      try {
        this.requiredValidator.validate(job, this.requiredFields);
        const jobOption = this.createJobOption(job);
        jobOptions.push(jobOption);
      } catch (e: unknown | MissingRequiredFieldsException) {
        if (e instanceof MissingRequiredFieldsException) {
          console.error(`Job with id ${job.cr9b4_ifoamjobid}: ${e.message}`);
        } else {
          throw e;
        }
      }
    }
    return this.jobsFactory.create(jobOptions);
  }

  createJob(response: DynamicsJob): Job {
    this.requiredValidator.validate(response, this.requiredFields);
    const jobOption = this.createJobOption(response);
    return this.jobFactory.create(jobOption);
  }

  getDynamicFields(jobOptions: Partial<JobOptions>): Partial<DynamicsJob> {
    if (!jobOptions.customerName) throw new Error("Customer name is required");
    if (!jobOptions.salesJobID) throw new Error("Sales edit-job ID is required");

    return {
      cr9b4_customername: jobOptions.customerName,
      cr9b4_salesjobnumber: jobOptions.salesJobID,
      cr9b4_serviceaddress: jobOptions.serviceAddress,
      cr9b4_phonenumber: jobOptions.phoneNumber,
      cr9b4_email: jobOptions.email,
      cr9b4_jobtype: jobOptions.jobType,
      cr9b4_campaign: jobOptions.campaign
    };
  }

  private createJobOption(job: DynamicsJob): JobOptions {
    const type = this.enumFromStringValue<JobTypeEnum>(JobTypeEnum, job.cr9b4_jobtype);
    const campaign = this.enumFromStringValue<CampaignEnum>(CampaignEnum, job.cr9b4_campaign);

    return {
      id: JobID.fromString(job.cr9b4_ifoamjobid),
      customerName: job.cr9b4_customername,
      serviceAddress: job.cr9b4_serviceaddress,
      phoneNumber: job.cr9b4_phonenumber,
      email: job.cr9b4_email,
      salesJobID: job.cr9b4_salesjobnumber,
      jobType: type,
      campaign: campaign
    };
  }

  private enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
    return (Object.values(enm) as unknown as string[]).includes(value)
      ? value as unknown as T
      : undefined;
  }
}
