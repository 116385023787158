import {ProductType} from "@modules/product/Application/Configuration/product-type";
import {ProductOverriddenPrice} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-price";
import {Price} from "./VO/price";

interface ProductOptions {
  id: string;
  productInternalID: string;
  originalPrice: Price;
  name: string;
  productType: ProductType;
  overriddenPrice?: ProductOverriddenPrice;
}

export abstract class Product {
  public readonly id: string;
  public readonly productInternalID: string;
  public readonly name: string;
  public readonly productType: ProductType;
  private readonly originalPrice: Price;
  private overriddenPrice: ProductOverriddenPrice;

  protected constructor(options: ProductOptions) {
    if (!options.id) {
      throw new Error('Product must have an id');
    }

    if (!options.name) {
      throw new Error('Product must have a name');
    }

    if (!options.originalPrice) {
      throw new Error('Product must have a price');
    }

    if (!options.productInternalID) {
      throw new Error('Product must have an internal id');
    }

    if (!options.productType) {
      throw new Error('Product must have a type');
    }

    this.id = options.id;
    this.name = options.name;
    this.originalPrice = options.originalPrice;
    this.productInternalID = options.productInternalID;
    this.productType = options.productType;
    this.overriddenPrice = options.overriddenPrice || new ProductOverriddenPrice({
      productID: this.id,
      price: this.originalPrice,
      type: this.productType,
    });
  }

  public overridePrice(price: ProductOverriddenPrice): Product {
    if (this.overriddenPrice && this.overriddenPrice.id && this.overriddenPrice.id !== price.id) {
      throw new Error('Product price is already overridden, you cannot override it with another ID');
    }
    this.overriddenPrice = price;
    return this;
  }

  public getPrice(): Price {
    return this.overriddenPrice ? this.overriddenPrice.price : this.originalPrice;
  }

  public getOriginalPrice(): Price {
    return this.originalPrice;
  }

  public isPriceOverridden(): boolean {
    return this.originalPrice.value !== this.getPrice().value;
  }

  public getOverriddenPrice(): ProductOverriddenPrice {
    return this.overriddenPrice;
  }
}
