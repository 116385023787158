import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {NotifyUserUseCase} from "../../../notification/Application/UseCase/notify-user-use-case";
import {AuthStateObservable, AuthStateObservableDI} from "../../Domain/auth-state-observable";
import {UserLogout, UserLogoutDI} from "../../Domain/user-logout";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(UserLogoutDI) private readonly userLogout: UserLogout,
    @Inject(AuthStateObservableDI) private readonly authService: AuthStateObservable,
    private readonly router: Router,
    private readonly notifyUserUseCase: NotifyUserUseCase
  ) {
  }

  ngOnInit() {
    this.userLogout.execute();

    this.authService.$execute()
      .pipe(takeUntil(this._destroying$))
      .subscribe(
        async (isLoggedIn) => {
          if (!isLoggedIn) {
            await this.notifyUserUseCase.execute('You have been logged out');
            await this.router.navigate(['/login']);
          }
        }
      );
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
