import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ProductGroupProvider} from "@modules/product/Application/Configuration/product-types-provider.service";
import {Product} from "@modules/product/Domain/Product/product";
import {Products} from "@modules/product/Domain/Product/products";
import {ProductRepository} from "@modules/product/Domain/Product/Repository/product-repository";
import {
  ProductOverriddenPriceRepository
} from "@modules/product/Domain/ProductOverriddenPrice/Repository/product-overridden-price-repository";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";

@Injectable({providedIn: 'root'})
export class GetProduct {
  constructor(
    private readonly productGroupProvider: ProductGroupProvider,
    private readonly productRepository: ProductRepository<Products<Product>, Product>,
    private readonly productPriceRepository: ProductOverriddenPriceRepository,
    private readonly getProfileUseCase: GetCurrentUserProfileUseCase
  ) {
  }

  async execute<T extends Product>(
    productTypeID: string,
    productID: string,
    businessUnitID?: BusinessUnitID
  ): Promise<T> {
    if (!businessUnitID) {
      const profile = await this.getProfileUseCase.execute();
      businessUnitID = profile.businessUnitID;
    }

    const configuration = this.productGroupProvider.getDynamicsConf(productTypeID);
    const product = await this.productRepository.getOne<T>(configuration, productID);

    try {
      const overriddenProductPrice = await this.productPriceRepository.getOne(
        product.id,
        product.productType,
        businessUnitID
      );
      product.overridePrice(overriddenProductPrice);
    } catch (e) {
      return product;
    }

    return product;
  }
}
