import {CommonModule} from '@angular/common';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {WidthLengthComponent} from "@modules/_shared/Component/width-length/width-length.component";
import {ClearZeroDirective} from "@modules/_shared/Directives/clear-zero.directive";
import {
  FoamCalculationStrategyFactory
} from "@modules/calculation-impl/foam/_calculator/Application/Factory/foam-calculation-strategy-factory.service";
import {
  initializeRoofPitchMethods
} from "@modules/calculation-impl/foam/_calculator/Application/Factory/initialize-roof-pitch-methods";
import {
  initializeRoofPitches
} from "@modules/calculation-impl/foam/_calculator/Application/Factory/initialize-roof-pitches";
import {
  FoamRoofPitchMethodsProvider
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-roof-pitch-methods-provider";
import {
  FoamRoofPitchesProvider
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-roof-pitches-provider";
import {FoamAreaComponent} from "@modules/calculation-impl/foam/_calculator/Presentation/foam-area/foam-area.component";
import {
  FoamTotalComponent
} from "@modules/calculation-impl/foam/_calculator/Presentation/foam-total/foam-total.component";
import {
  AngularSyncFoamRoofPitchMethodsProviderServiceService
} from "@modules/calculation-impl/foam/roofPitch/Infrastructure/angular-sync-foam-roof-pitch-methods-provider-service.service";
import {
  AngularSyncFoamRoofPitchesProviderService
} from "@modules/calculation-impl/foam/roofPitch/Infrastructure/angular-sync-foam-roof-pitches-provider.service";
import {
  RoofPitchModalComponent
} from "@modules/calculation-impl/foam/roofPitch/Presentation/roof-pitch-modal/roof-pitch-modal.component";
import {
  AddCalculationStrategyCommand
} from "@modules/calculation-strategy/Application/UseCase/Command/add-calculation-strategy.service";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {CrewSelectorComponent} from "@modules/gm-inputs/Presentation/crew-selector/crew-selector.component";

export const FOAM_CALCULATION_ID = CalculationStrategyID.fromString('foam');

@NgModule({
  declarations: [
    FoamAreaComponent,
    FoamTotalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    WidthLengthComponent,
    CrewSelectorComponent,
    ClearZeroDirective,
    RoofPitchModalComponent
  ],
  providers: [
    {provide: FoamRoofPitchesProvider, useExisting: AngularSyncFoamRoofPitchesProviderService},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeRoofPitches,
      deps: [AngularSyncFoamRoofPitchesProviderService],
      multi: true
    },
    {provide: FoamRoofPitchMethodsProvider, useExisting: AngularSyncFoamRoofPitchMethodsProviderServiceService},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeRoofPitchMethods,
      deps: [AngularSyncFoamRoofPitchMethodsProviderServiceService],
      multi: true
    }
  ]
})
export class FoamCalculationModule {
  constructor(
    addCalculationStrategyCommand: AddCalculationStrategyCommand,
    strategyFactory: FoamCalculationStrategyFactory
  ) {
    const strategy = strategyFactory.execute();
    addCalculationStrategyCommand.execute(strategy).then(r => r);
  }
}
