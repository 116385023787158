import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import {Estimates} from "../Aggregate/estimates";
import {Estimate} from "../estimate";
import {EstimateOptions} from "../estimate-options";

export abstract class EstimateRepository {
  /**
   * @param jobID
   * @throws EstimatesNotFoundException
   */
  abstract getEstimatesByJobID(jobID: string): Promise<Estimates>;

  abstract updateEstimate(estimate: Partial<EstimateOptions>): Promise<Estimate>;

  abstract createEstimate(estimate: Partial<Estimate>): Promise<Estimate>;

  abstract deleteEstimate(estimateID: EstimateID): Promise<void>;

  abstract getEstimateByID(estimateID: EstimateID): Promise<Estimate>;

  abstract getEstimatesJobIDs(): Promise<string[]>;
}
