export enum CampaignEnum {
  OrganicRealtor = 'Organic - Realtor/Insurance Agent',
  OrganicConstructConnect = 'Organic - ConstructConnect',
  OrganicDoorHanger = 'Organic - Door Hanger',
  OrganicFriendsFamily = 'Organic - Friends/Family',
  OrganicGeneralContractor = 'Organic - General Contractor',
  OrganicLocalMarketing = 'Organic - Local Marketing',
  OrganicNetworking = 'Organic - Networking',
  OrganicReferral = 'Organic - Referral',
  OrganicTradeShow = 'Organic - Trade Show',
  OrganicZeePartnership = 'Organic - ZeePartnership',
  OrganicYardSign = 'Organic - Yard Sign',
  OrganicOther = 'Organic - Other',
  PaidAngi = 'Paid - Angi',
  PaidBillboard = 'Paid - Billboard',
  PaidEDDM = 'Paid - EDDM',
  PaidBing = 'Paid - Bing',
  PaidELocal = 'Paid - eLocal',
  PaidFacebook = 'Paid - Facebook',
  PaidGetFelix = 'Paid - Get Felix',
  PaidGoogleGMB = 'Paid - Google GMB/GBP/Website',
  PaidGoogleAds = 'Paid - Google Paid Ads/PPC',
  PaidIPTargetingAds = 'Paid - IP Targeting Ads',
  PaidLocalSearch = 'Paid - Local Search',
  PaidMagazineNewspaper = 'Paid - Magazine/Newspaper',
  PaidMarchex = 'Paid - Marchex',
  PaidRadio = 'Paid - Radio',
  PaidNetworx = 'Paid - Networx',
  PaidScorpionBookingTool = 'Paid - Scorpion Booking Tool',
  PaidRingPartner = 'Paid - Ring Partner',
  PaidScorpionDirectory = 'Paid - Scorpion Directory',
  PaidSimpliFi = 'Paid - SimpliFi',
  PaidThumbtack = 'Paid - Thumbtack',
  PaidTV = 'Paid - TV',
  PaidValpak = 'Paid - Valpak',
  PaidVehicleTrailerWrap = 'Paid - Vehicle/Trailer Wrap',
  PaidWaze = 'Paid - Waze',
  PaidYahooGemini = 'Paid - Yahoo Gemini',
  PaidYelp = 'Paid - Yelp',
  PaidZeePartnerships = 'Paid - ZeePartnerships',
}
