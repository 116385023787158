import {Injectable} from "@angular/core";
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";
import {BusinessUnitRepository} from "@modules/business-unit/Domain/BusinessUnit/Repository/business-unit-repository";

@Injectable({providedIn: 'root'})
export class GetBusinessUnitsUseCase {
  constructor(
    private readonly businessUnitRepository: BusinessUnitRepository
  ) {
  }

  async execute(): Promise<BusinessUnits> {
    return await this.businessUnitRepository.getAll();
  }
}
