import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {environment} from "@modules/environments/environment";
import {DYNAMICS_TTL_TOKEN} from "@modules/microsoft-dynamics/Application/Type/dynamics-ttl";
import {DynamicsWebApi} from "dynamics-web-api";
import {MsAlAuthServiceType} from "../microsoft-auth/Application/DI/ms-al-auth-service-type";
import {dynamicsWebApiFactory} from "./Application/Factory/dynamics-web-api-factory";
import {MsDynamicsBaseUrlType} from "./Application/Type/ms-dynamics-base-url-type";


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: DynamicsWebApi,
      useFactory: dynamicsWebApiFactory,
      deps: [MsAlAuthServiceType, MsDynamicsBaseUrlType]
    },
    {provide: MsDynamicsBaseUrlType, useValue: environment.dynamicsConfig.baseUrl},
    {provide: DYNAMICS_TTL_TOKEN, useValue: environment.dynamicsConfig.cacheTimeToLive},
  ]
})
export class MicrosoftDynamicsModule {
}
