export class FoamService {
  private static OPEN_CELL_FOAM = "Open Cell Foam";
  private static CLOSED_CELL_FOAM = "Closed Cell Foam";
  private static INTUMESCENT_COATING = "Intumescent Coating";

  constructor(public readonly value: string) {
    if (!this.isValid(value)) {
      throw new Error(`Invalid value: ${value}`);
    }
  }

  public static get openCellFoam(): string {
    return FoamService.OPEN_CELL_FOAM;
  }

  public static get intumescentCoating(): string {
    return FoamService.INTUMESCENT_COATING;
  }

  static getAvailableValues() {
    return [FoamService.OPEN_CELL_FOAM, FoamService.CLOSED_CELL_FOAM, FoamService.INTUMESCENT_COATING];
  }

  isValid(value: string) {
    return FoamService.getAvailableValues().includes(value);
  }

  isEqual(service: FoamService): boolean {
    return this.value === service.value;
  }
}
