import {Injectable} from "@angular/core";
import {Estimate} from "@modules/estimate/Domain/EstimateOption/estimate";
import {EstimateOptions} from "@modules/estimate/Domain/EstimateOption/estimate-options";
import {EstimateRepository} from "@modules/estimate/Domain/EstimateOption/Repository/estimate-repository";


@Injectable({
  providedIn: 'root'
})
export class CreateEstimateUseCase {
  constructor(
    private estimateRepository: EstimateRepository
  ) {
  }

  async execute(options: Partial<EstimateOptions>): Promise<Estimate> {
    return this.estimateRepository.createEstimate(options);
  }
}
