import {Injectable} from "@angular/core";
import {Jobs} from "../../../Domain/Job/Aggregate/jobs";
import {JobOptions} from "../../../Domain/Job/job-options";
import {JobFactory} from "./job-factory.service";

@Injectable({
  providedIn: 'root'
})
export class JobsFactory {
  constructor(
    private readonly jobFactory: JobFactory
  ) {
  }

  create(jobOptionsList: JobOptions[]): Jobs {
    return new Jobs(
      jobOptionsList.map(jobOptions => this.jobFactory.create(jobOptions))
    );
  }
}
