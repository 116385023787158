import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {NgModule} from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from "@azure/msal-angular";
import {IonicModule} from "@ionic/angular";
import {SendEmailService} from "@modules/microsoft-auth/Domain/Email/Service/send-email-service";
import {GraphSendEmailService} from "@modules/microsoft-auth/Infrastructure/SendEmailService/graph-send-email-service";
import {HeaderModule} from "../header/header.module";
import {MsAlAuthServiceType} from "./Application/DI/ms-al-auth-service-type";
import {MsalGuardConfigFactory} from "./Application/Factory/msal-guard-config-factory";
import {MsalInterceptorConfigFactory} from "./Application/Factory/msal-interceptor-config-factory";
import MsAlAuthServiceProvider from "./Application/Service/ms-al-auth-service-provider";
import {AuthStateObservableDI} from "./Domain/auth-state-observable";
import {MsAlLoggerDIToken} from "./Domain/ms-al-logger";
import {UserLoginDI} from "./Domain/user-login";
import {UserLogoutDI} from "./Domain/user-logout";
import {MsAlAuthState} from "./Infrastructure/AuthStateObservable/ms-al-auth-state.service";
import MsAlNullLogger from "./Infrastructure/ms-al-null-logger";
import {MsAlUserLogin} from "./Infrastructure/UserLogin/ms-al-user-login.service";
import {MsAlUserLogout} from "./Infrastructure/UserLogout/ms-al-user-logout.service";
import {MicrosoftAuthRoutingModule} from "./microsoft-auth-routing.module";
import {LoginPageComponent} from "./Presentation/login-page/login-page.component";
import {LogoutComponent} from "./Presentation/logout/logout.component";
import {MicrosoftAuthAppComponent} from "./Presentation/microsoft-auth-app/microsoft-auth-app.component";

@NgModule({
  declarations: [
    LoginPageComponent,
    LogoutComponent,
    MicrosoftAuthAppComponent
  ],
  exports: [
    MicrosoftAuthAppComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MsalModule,
    MicrosoftAuthRoutingModule,
    HeaderModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory
    },
    {
      provide: MsAlLoggerDIToken,
      useValue: MsAlNullLogger
    },
    {
      provide: AuthStateObservableDI,
      useClass: MsAlAuthState
    },
    {
      provide: UserLoginDI,
      useClass: MsAlUserLogin
    },
    {
      provide: UserLogoutDI,
      useClass: MsAlUserLogout
    },
    {
      provide: MsAlAuthServiceType,
      useClass: MsAlAuthServiceProvider
    },
    {
      provide: SendEmailService,
      useExisting: GraphSendEmailService,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [
    MsalRedirectComponent,
    MicrosoftAuthAppComponent
  ]
})
export class MicrosoftAuthModule {
}
