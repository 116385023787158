import {Injectable} from "@angular/core";
import {CreateEstimateUseCase} from "@modules/estimate/Application/UseCase/Command/create-estimate-use-case";
import {DeleteEstimateUseCase} from "@modules/estimate/Application/UseCase/Command/delete-estimate-use-case";
import {UpdateEstimateUseCase} from "@modules/estimate/Application/UseCase/Command/update-estimate-use-case";
import {GetEstimateByIdUseCase} from "@modules/estimate/Application/UseCase/Query/get-estimate-by-id-use-case.service";
import {GetEstimatesByJobIdUseCase} from "@modules/estimate/Application/UseCase/Query/get-estimates-by-job-id-use-case";
import {Estimates} from "@modules/estimate/Domain/EstimateOption/Aggregate/estimates";
import {Estimate} from "@modules/estimate/Domain/EstimateOption/estimate";
import {EstimateOptions} from "@modules/estimate/Domain/EstimateOption/estimate-options";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";

import {EstimateFormData} from "./estimate-form-data";

@Injectable({
  providedIn: 'root'
})
export class EstimateService {
  constructor(
    private readonly getEstimatesByJobIdUseCase: GetEstimatesByJobIdUseCase,
    private readonly getEstimateByIdUseCase: GetEstimateByIdUseCase,
    private readonly deleteEstimateUseCase: DeleteEstimateUseCase,
    private readonly updateEstimateUseCase: UpdateEstimateUseCase,
    private readonly createEstimateUseCase: CreateEstimateUseCase,
  ) {
  }

  getEstimatesByJobId(jobID: string): Promise<Estimates> {
    return this.getEstimatesByJobIdUseCase.execute(jobID);
  }

  getEstimateById(estimateID: EstimateID): Promise<Estimate> {
    return this.getEstimateByIdUseCase.execute(estimateID);
  }

  async deleteEstimate(estimateID: EstimateID): Promise<void> {
    return this.deleteEstimateUseCase.execute(estimateID);
  }

  async updateEstimate(estimateID: EstimateID, estimateData: EstimateFormData): Promise<Estimate> {
    if (!estimateID) throw new Error('Estimate ID is required');
    if (!estimateData.name) throw new Error('Estimate name is required');

    const estimateOptions: Partial<EstimateOptions> = {
      id: estimateID,
      name: estimateData.name,
    }

    return await this.updateEstimateUseCase.execute(estimateOptions);
  }

  async createEstimate(jobID: string, estimateData: EstimateFormData): Promise<Estimate> {
    if (!jobID) throw new Error('Job ID is required');
    if (!estimateData.name) throw new Error('Estimate name is required');

    const estimateOptions: Partial<EstimateOptions> = {
      name: estimateData.name,
      jobID: jobID,
    }

    return await this.createEstimateUseCase.execute(estimateOptions);
  }
}
