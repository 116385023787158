import {Crews} from "@modules/gm-inputs/Domain/Crew/crews";
import {Crew} from "@modules/gm-inputs/Domain/Crew/VO/crew";
import {OtherRates} from "../Other/other-rates";

export class GmInputs {

  constructor(
    public readonly id: string,
    public readonly zeeName: string,
    private crews: Crews,
    private otherRates: OtherRates
  ) {
  }

  getCrews(): Crews {
    return this.crews;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getOveragePercentage(): number {
    return this.otherRates.overagePercentage.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getBenefitsRatePercentage(): number {
    return this.otherRates.benefitsRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getSalesTaxRatePercentage(): number {
    return this.otherRates.salesTaxRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getRoyaltyRatePercentage(): number {
    return this.otherRates.royaltyRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getCommissionRatePercentage(): number {
    return this.otherRates.commissionRate.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  getCCPercent(): number {
    return this.otherRates.ccPercent.value;
  }

  /**
   * @return float The value where 0.00 represents 0% and 1.00 represents 100%.
   */

  setOtherRates(otherRates: OtherRates): void {
    this.otherRates = otherRates;
  }

  setCrews(crews: Crews): void {
    this.crews = crews;
  }

  getTotallyCrewsHourlyRate(): number {
    return this.crews.getTotalHourlyRate();
  }

  getCrewFullyLoadedRate(crew: Crew): number {
    return crew.rate * (1 + this.getBenefitsRatePercentage());
  }

  getTotallyLoadedCrewsHourlyRate(): number {
    return this.crews.getTotalHourlyRate() * (1 + this.getBenefitsRatePercentage());
  }

  getLaborExpense(projHours: number, crewSize: number): number {
    try {
      const techsUsed = this.crews.getCrewsUsed(crewSize);
      const totalHourlyRate = techsUsed.reduce((acc, crew) => {
        return acc + crew.rate;
      }, 0);
      return totalHourlyRate * projHours;
    } catch (_e) {
      return 0;
    }
  }

  getLaborFullyLoadedExpense(projHours: number, crewSize: number): number {
    return this.getLaborExpense(projHours, crewSize) * (1 + this.getBenefitsRatePercentage());
  }

  checkPin(pin: string) {
    return this.zeeName === pin;
  }
}
