import {HttpErrorResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {NotifyUserUseCase} from "../../../notification/Application/UseCase/notify-user-use-case";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private notifyUserUseCase: NotifyUserUseCase) {
  }

  async execute(message: unknown): Promise<void> {
    if (typeof message === 'string') {
      return this.notifyUserUseCase.execute(message);
    }

    if (message instanceof Error) {
      return this.notifyUserUseCase.execute(message.message);
    }

    if (message instanceof HttpErrorResponse) {
      if (message.error && message.error.message) {
        return this.notifyUserUseCase.execute(message.error.message);
      }
    }

    return this.notifyUserUseCase.execute('Unknown error ' + message);
  }
}
