import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalController} from "@ionic/angular";
import {ShowTabNameService} from "@modules/_shared/Service/ShowTabName/show-tab-name.service";
import {
  CalculatorAreaComponent
} from "@modules/calculation-area/Domain/CalculatorAreaComponent/calculator-area-component";
import {
  FoamAreaFactory
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-area-factory.service";
import {GetDepthsUseCase} from "@modules/calculation-impl/foam/depth/Application/UseCase/get-depths-use-case";
import {Depth} from "@modules/calculation-impl/foam/depth/Domain/depth";
import {
  RoofPitchModalComponent
} from "@modules/calculation-impl/foam/roofPitch/Presentation/roof-pitch-modal/roof-pitch-modal.component";
import {
  GetAvailableServicesUseCase
} from "@modules/calculation-impl/foam/service/Application/UseCase/get-available-services-use-case";
import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {
  EnergyStarRecommendationService
} from "@modules/energy-star-recommendation/Presentation/Service/energy-star-recommendation.service";
import {GetProductsByType} from "@modules/product/Application/UseCase/Query/get-products-by-type";
import {FoamProductType} from "@modules/product/Configurations/foam-product/Application/foam-product-type";
import {FoamProduct} from "@modules/product/Configurations/foam-product/Domain/foam-product";
import {FoamProducts} from "@modules/product/Configurations/foam-product/Domain/foam-products";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

import {FoamArea, FoamAreaOptions} from "../../Domain/Calculator/CalculationArea/foam-area";

@Component({
  selector: 'app-foam-area',
  templateUrl: './foam-area.component.html',
  styleUrls: ['./foam-area.component.scss'],
})
export class FoamAreaComponent implements CalculatorAreaComponent<FoamArea>, OnInit, OnDestroy {
  @Input() inputModel: FoamArea | undefined;
  @Output() inputModelChange: EventEmitter<FoamArea> = new EventEmitter<FoamArea>();
  protected form: FormGroup;
  protected filteredProducts?: FoamProducts;
  protected availableDepths: Depth[] = [];
  protected services: FoamService[] = []
  protected displayPitchSelector: boolean = false;
  protected availableProducts?: FoamProducts;
  protected isPitchFactorEnabled: boolean = false;
  private allDepths: Depth[] = [];
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly getProductsUseCase: GetProductsByType,
    private readonly getAvailableServicesUseCase: GetAvailableServicesUseCase,
    private readonly getDepthsUseCase: GetDepthsUseCase,
    private readonly foamAreaFactory: FoamAreaFactory,
    private readonly energyStarRecommendationService: EnergyStarRecommendationService,
    private readonly showTabNameService: ShowTabNameService,
    private readonly modalCtrl: ModalController,
  ) {
    this.form = new FormGroup({
      service: new FormControl(null, [Validators.required]),
      sqft: new FormControl(0, [Validators.required]),
      depth: new FormControl(1, [Validators.required, Validators.min(1)]),
      projHours: new FormControl(0, [Validators.required]),
      crewSize: new FormControl(0, [Validators.required]),
      miscellaneous: new FormControl(0, [Validators.required]),
      product: new FormControl(null, [Validators.required]),
      roofPitchName: new FormControl(null),
      roofPitchMethodName: new FormControl(null),
    });
  }


  async ngOnInit(): Promise<void> {
    this.services = await this.getAvailableServicesUseCase.execute();
    this.allDepths = await this.getDepthsUseCase.execute();
    this.availableProducts = await this.getProductsUseCase.execute<FoamProducts, FoamProduct>(FoamProductType.id);

    this.initForm();

    const roofRegex = /roofline/i;

    this.showTabNameService.tabName$
      .pipe(takeUntil(this.destroy$))
      .subscribe((tabName) => {
        this.displayPitchSelector = roofRegex.test(tabName);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processChanges(values: any) {
    if (!values || !this.inputModel) return;

    this.displayAvailableProducts();
    this.displayAvailableDepths();

    const params: FoamAreaOptions = {
      id: this.inputModel.id,
      name: this.inputModel.name,
      service: values.service,
      sqft: this.inputModel.getRoofPitch() ? this.inputModel.getRawSqft() : values.sqft,
      depth: values.depth,
      projHours: values.projHours,
      laborCrew: values.crewSize,
      miscellaneous: values.miscellaneous,
      product: values.product,
      roofPitchName: values.roofPitchName,
      roofPitchMethodName: values.roofPitchMethodName
    }

    const inputModel = this.foamAreaFactory.execute(params);
    this.inputModel = inputModel;

    this.inputModelChange.emit(inputModel);
  }

  async openEnergyRecommendationModal() {
    await this.energyStarRecommendationService.openModal();
  }

  async roofPitchChanged(event: CustomEvent) {
    const value = event.detail.checked;
    if (!value) {
      this.disablePitchFactor();
      return;
    }

    const modal = await this.modalCtrl.create({
      component: RoofPitchModalComponent,
      componentProps: {
        inputModel: this.inputModel
      }
    });

    await modal.present();

    const modalResult = await modal.onWillDismiss<FoamArea>();

    if (modalResult?.role !== 'confirm' || !modalResult.data) {
      this.disablePitchFactor();
      return;
    }

    this.enablePitchFactor(modalResult.data);
  }

  private initForm() {
    if (!this.inputModel) {
      throw new Error('inputModel is required');
    }
    this.form.patchValue({
      service: this.inputModel.service,
      sqft: null,
      depth: this.inputModel.depth,
      projHours: this.inputModel.projHours,
      crewSize: this.inputModel.laborCrew,
      miscellaneous: this.inputModel.miscellaneous,
      product: this.inputModel.product,
      pitchFactorName: this.inputModel.getRoofPitch()?.pitch.name,
      pitchFactorMethod: this.inputModel.getRoofPitch()?.method,
    });

    this.isPitchFactorEnabled = !!this.inputModel.getRoofPitch();

    if (this.inputModel.getRoofPitch()) {
      this.form.get('sqft')?.setValue(this.inputModel.getSqft(), {emitEvent: false});
      this.form.get('sqft')?.disable({emitEvent: false});
    } else {
      this.form.get('sqft')?.setValue(this.inputModel.getRawSqft(), {emitEvent: false});
      this.form.get('sqft')?.enable({emitEvent: false});
    }

    this.displayAvailableProducts();
    this.displayAvailableDepths();

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((values) => this.processChanges(values));
  }

  private displayAvailableProducts(): void {
    if (!this.availableProducts) throw new Error('availableProducts is required');

    const serviceName = this.form.get('service')?.value;
    if (!serviceName) {
      this.filteredProducts = undefined;
      return;
    }

    const service = new FoamService(serviceName);
    this.filteredProducts = this.availableProducts.filterProductsByService(service);
  }

  private displayAvailableDepths() {
    const service = this.form.get('service')?.value;

    if (service === FoamService.intumescentCoating) {
      this.availableDepths = [new Depth(1)];
      this.form.get('depth')?.setValue(1, {emitEvent: false});
      this.form.get('depth')?.disable({emitEvent: false});
      return;
    }

    this.form.get('depth')?.enable({emitEvent: false});
    this.availableDepths = this.allDepths;
  }

  private disablePitchFactor() {
    this.form.patchValue({
      pitchFactorName: null,
      pitchFactorMethod: null,
      sqft: this.inputModel?.getRawSqft(),
    }, {emitEvent: false});

    this.inputModel?.disassociateRoofPitch();
    this.isPitchFactorEnabled = false;
    this.form.get('sqft')?.enable({emitEvent: true});
  }

  private enablePitchFactor(model: FoamArea) {
    this.inputModel = model;

    this.form.patchValue({
      roofPitchName: model.getRoofPitch()?.pitch.name.getValue(),
      roofPitchMethodName: model.getRoofPitch()?.method.name.getValue(),
      sqft: this.inputModel?.getSqft(),
    }, {emitEvent: false});

    this.isPitchFactorEnabled = true;
    this.form.get('sqft')?.disable({emitEvent: true});
  }
}
