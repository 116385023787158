import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";

interface BusinessUnitOptions {
  id: string;
  name: string;
  website: string;
  email: string;
  mainPhone: string;
  parentBusinessUnitID?: string;
}

export class BusinessUnit {
  public readonly id: BusinessUnitID;
  public readonly name: string;
  public readonly website: string;
  public readonly email: string;
  public readonly mainPhone: string;
  public readonly parentBusinessUnitID?: BusinessUnitID;

  constructor(options: BusinessUnitOptions) {
    if (!options.id) throw new Error('Business unit ID is required');
    if (!options.name) throw new Error('Business unit name is required');


    this.id = BusinessUnitID.fromString(options.id);
    this.name = options.name;
    this.website = options.website;
    this.email = options.email;
    this.mainPhone = options.mainPhone;
    this.parentBusinessUnitID = options.parentBusinessUnitID
      ? BusinessUnitID.fromString(options.parentBusinessUnitID)
      : undefined;
  }

  ifApplicableForSearch(searchTerm: string): boolean {
    const name = this.name.toLowerCase();
    return name.includes(searchTerm);
  }
}

