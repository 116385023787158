import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {GmInputsRepository} from "@modules/gm-inputs/Domain/GmInputs/Repository/gm-inputs-repository";
import {
  DynamicsGmInputsFactory
} from "@modules/gm-inputs/Infrastructure/Repository/DynamicsGmInputsRepository/Factory/dynamics-gm-inputs-factory";
import {
  DynamicsGmInputs
} from "@modules/gm-inputs/Infrastructure/Repository/DynamicsGmInputsRepository/Type/dynamics-gm-inputs";
import {
  DynamicsCreateCommand
} from "@modules/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsUpdateCommand
} from "@modules/microsoft-dynamics/Application/UseCase/Command/dynamics-update-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {CreateRequest, RetrieveMultipleRequest, RetrieveRequest, UpdateRequest} from "dynamics-web-api";

@Injectable({
  providedIn: 'root'
})
export class DynamicsGmInputsRepository extends GmInputsRepository {

  public static readonly tableName = "cr9b4_ifoamcalculatorglobalinputses";
  private readonly selectFields = ["cr9b4_ifoamcalculatorglobalinputsid", "cr9b4_title", "cr9b4_inputs"];

  constructor(
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery,
    private readonly dynamicsUpdateCommand: DynamicsUpdateCommand,
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly factory: DynamicsGmInputsFactory,
  ) {
    super();
  }

  async getGmInputs(id: string): Promise<GmInputs> {
    const request: RetrieveRequest = {
      collection: DynamicsGmInputsRepository.tableName,
      key: id,
      select: this.selectFields,
    };

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsGmInputs>(request);
    return this.factory.create(response);
  }

  async getAllAvailableGmInputs(): Promise<GmInputs[]> {
    const request: RetrieveMultipleRequest = {
      collection: DynamicsGmInputsRepository.tableName,
      select: this.selectFields
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsGmInputs>(request);
    return response.value.map((data) => this.factory.create(data));
  }

  async updateGmInputs(gmInputs: GmInputs): Promise<GmInputs> {
    const request: UpdateRequest = {
      collection: DynamicsGmInputsRepository.tableName,
      key: gmInputs.id,
      data: this.factory.getDynamicFields(gmInputs),
      returnRepresentation: true,
      select: this.selectFields
    };

    const response = await this.dynamicsUpdateCommand.execute<DynamicsGmInputs>(request);
    return this.factory.create(response);
  }

  async getByBusinessUnitID(businessUnitID: BusinessUnitID): Promise<GmInputs> {
    const request: RetrieveMultipleRequest = {
      collection: DynamicsGmInputsRepository.tableName,
      filter: `_owningbusinessunit_value eq ${businessUnitID}`,
      select: this.selectFields
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsGmInputs>(request);
    if (response.value.length === 0) {
      throw new Error('GM Inputs not found for the given business unit ID: ' + businessUnitID);
    }

    return this.factory.create(response.value[0]);
  }

  async createGmInputs(gmInputs: Partial<GmInputs>): Promise<GmInputs> {
    const data = this.factory.getDynamicFields(gmInputs);
    const request: CreateRequest = {
      collection: DynamicsGmInputsRepository.tableName,
      data,
      returnRepresentation: true
    }

    const response = await this.dynamicsCreateCommand.execute<DynamicsGmInputs>(request);
    return this.factory.create(response);
  }
}
