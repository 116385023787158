import {Injectable} from "@angular/core";
import {Job} from "../../../Domain/Job/job";
import {JobOptions} from "../../../Domain/Job/job-options";

@Injectable({
  providedIn: 'root'
})
export class JobFactory {
  create(options: JobOptions): Job {
    return new Job(options);
  }
}
