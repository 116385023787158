import {CampaignEnum} from "../Enum/campaign-enum";
import {JobTypeEnum} from "../Enum/job-type-enum";

export class JobIdentifiers {
  readonly salesJobID: string;
  readonly jobType: JobTypeEnum | undefined;
  readonly campaign: CampaignEnum | undefined;

  constructor(
    salesJobID: string,
    jobType: JobTypeEnum | undefined,
    campaign: CampaignEnum | undefined
  ) {
    if (!salesJobID) throw new Error("Sales Job ID is required");

    this.salesJobID = salesJobID;
    this.jobType = jobType;
    this.campaign = campaign;
  }
}
