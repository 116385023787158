import {Injectable} from "@angular/core";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";

@Injectable({
  providedIn: 'root'
})
export class CalculationLinkProvider {

  public static BASE_URL: string = 'calculation';

  public static getCalculationsListRouterLink(estimateID: EstimateID): string[] {
    return [
      ...CalculationLinkProvider.getBaseRouterLink(),
      'list',
      estimateID.getValue()
    ]
  }

  private static getBaseRouterLink(): string[] {
    return ['/', CalculationLinkProvider.BASE_URL];
  }
}
