import {Injectable} from "@angular/core";
import {CalculationAreaFactory} from "@modules/calculation-area/Domain/CalculationArea/Factory/calculation-area-factory";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  MiscAreaOptions
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-options";

@Injectable({
  providedIn: 'root'
})
export class MiscAreaFactory extends CalculationAreaFactory<MiscArea, MiscAreaOptions> {
  private readonly defaults: MiscAreaOptions = {
    id: '',
    name: 'Miscellaneous',
    items: []
  }

  execute(data: Partial<MiscAreaOptions> = {}): MiscArea {
    const dataItems = data.items || [];
    const mergedData: MiscAreaOptions = {
      id: data.id || this.defaults.id,
      name: data.name || this.defaults.name,
      items: [...this.defaults.items, ...dataItems]
    }
    return new MiscArea(mergedData);
  }
}
