import {Inject, Injectable} from "@angular/core";
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {
  GetBusinessUnitsUseCase
} from "@modules/business-unit/Application/UseCase/Query/get-business-units-use-case.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {Profile} from "@modules/profile/Domain/Profile/profile";
import {DynamicsProfileValue} from "@modules/profile/Infrastructure/Repository/Type/dynamics-profile";

@Injectable({providedIn: 'root'})
export class ProfileFactory {
  constructor(
    private readonly getBusinessUnits: GetBusinessUnitsUseCase,
    @Inject(RootBusinessUnit) private readonly rootBusinessUnitID: string,
  ) {
  }

  public async fromDynamicsProfile(dynamicsProfileValue: DynamicsProfileValue): Promise<Profile> {
    const profile = new Profile({
      fullName: dynamicsProfileValue.fullname,
      title: dynamicsProfileValue.title,
      email: dynamicsProfileValue.internalemailaddress,
      businessUnitId: dynamicsProfileValue.businessunitid.businessunitid,
      businessUnitName: dynamicsProfileValue.businessunitid.name
    });

    const businessUnits = await this.getBusinessUnits.execute();
    if (businessUnits.checkIfSameOrAscendant(profile.businessUnitID, BusinessUnitID.fromString(this.rootBusinessUnitID))) {
      profile.enableAdmin();
    }
    return profile;
  }

}

