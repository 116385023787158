import {NgForOf, NgIf} from "@angular/common";
import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {GetCurrentUserGmInputs} from "@modules/gm-inputs/Application/UseCase/Query/get-current-user-gm-inputs";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";

@Component({
  selector: 'app-crew-selector',
  templateUrl: './crew-selector.component.html',
  styleUrls: ['./crew-selector.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    NgIf,
    ReactiveFormsModule,
    NgForOf
  ]
})
export class CrewSelectorComponent implements OnInit {
  @Input() public form: FormGroup = new FormGroup({});

  protected loading = false;
  protected gmInputs: GmInputs | undefined;


  constructor(
    private readonly getCurrentUserGmInputs: GetCurrentUserGmInputs,
    private readonly notificationService: NotificationService,
  ) {
  }


  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      this.gmInputs = await this.getCurrentUserGmInputs.execute();
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }

  getCrewName(i: number): string {
    if (!this.gmInputs) return '';

    const crew = this.gmInputs.getCrews().value;
    if (crew.length === 0) return '';

    let label: string = `${i} (`;
    const crewNames = crew.slice(0, i).map(c => c.name);
    label += crewNames.join(', ');
    label += ')';
    return label;
  }
}
