import {Injectable} from "@angular/core";
import {MsalService} from "@azure/msal-angular";
import {AuthenticationResult, InteractionRequiredAuthError} from "@azure/msal-browser";
import MsAlAuthService from "../../Domain/ms-al-auth-service";

@Injectable({
  providedIn: 'root'
})
class MsAlAuthServiceProvider implements MsAlAuthService {
  constructor(
    private readonly msAlInstanceProvider: MsalService
  ) {
  }

  async getAccessToken(scopes: string[]): Promise<AuthenticationResult> {
    const instance = this.msAlInstanceProvider.instance;
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      throw new Error("No accounts found");
    }

    try {
      return await instance.acquireTokenSilent({
        scopes,
        account: accounts[0]
      });
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        if ('login_required' === e.errorCode) {
          this.msAlInstanceProvider.loginRedirect();
        } else {
          this.msAlInstanceProvider.acquireTokenRedirect({scopes});
        }
      }
      return Promise.reject(e);
    }
  }
}

export default MsAlAuthServiceProvider;
