import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ProductOverriddenPrice} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-price";
import {
  ProductOverriddenPriceRepository
} from "@modules/product/Domain/ProductOverriddenPrice/Repository/product-overridden-price-repository";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";

@Injectable({providedIn: 'root'})
export class UpdateProductOverriddenPrice {
  constructor(
    private readonly productOverriddenPriceRepository: ProductOverriddenPriceRepository,
    private readonly getProfileUseCase: GetCurrentUserProfileUseCase
  ) {
  }

  async execute(
    overriddenPrice: ProductOverriddenPrice,
    businessUnitID?: BusinessUnitID
  ): Promise<ProductOverriddenPrice> {
    if (!businessUnitID) {
      const profile = await this.getProfileUseCase.execute();
      businessUnitID = profile.businessUnitID;
    }

    if (overriddenPrice.id) {
      return await this.productOverriddenPriceRepository.update(overriddenPrice, businessUnitID);
    }

    return await this.productOverriddenPriceRepository.create(overriddenPrice, businessUnitID);
  }
}
