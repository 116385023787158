import {Injectable} from "@angular/core";
import {MissingRequiredFieldsException} from "@modules/_shared/Service/Validator/missing-required-fields.exception";
import {RequiredFieldsValidator} from "@modules/_shared/Service/Validator/required-fields-validator.service";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {
  CalculationStrategyNotFound
} from "@modules/calculation-strategy/Domain/CalculationStrategy/Exception/calculation-strategy-not-found";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {CalculationFactory} from "@modules/calculation/Application/Factory/calculation-factory.service";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationOptions} from "@modules/calculation/Domain/Calculation/calculation-options";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";
import {RetrieveMultipleResponse} from "dynamics-web-api";
import {DynamicsEstimateCalculation} from "../Type/dynamics-estimate-calculation";

@Injectable({
  providedIn: 'root'
})
export class DynamicsCalculationFactory {
  private readonly requiredFields: (keyof DynamicsEstimateCalculation)[] = [
    "cr9b4_calculationtype",
    "cr9b4_ifoamestimatecalculationid",
    "_cr9b4_estimateid_value"
  ];

  constructor(
    private readonly requiredValidator: RequiredFieldsValidator,
    private readonly estimateCalculationFactory: CalculationFactory,
  ) {
  }

  createCalculation<TStrategy extends CalculationStrategy>(response: DynamicsEstimateCalculation): Promise<Calculation<TStrategy>> {
    this.requiredValidator.validate(response, this.requiredFields);
    const estimateCalculationOption = this.createEstimateCalculationOption(response);
    return this.estimateCalculationFactory.create<TStrategy>(estimateCalculationOption);
  }

  async createCalculations(response: RetrieveMultipleResponse<DynamicsEstimateCalculation>): Promise<Calculation[]> {
    const estimateCalculations: Calculation[] = [];
    for (const estimateCalculationResponse of response.value) {
      try {
        this.requiredValidator.validate(estimateCalculationResponse, this.requiredFields);
        const estimateCalculation = await this.createCalculation(estimateCalculationResponse);
        estimateCalculations.push(estimateCalculation);
      } catch (e: unknown | MissingRequiredFieldsException) {
        if (e instanceof CalculationStrategyNotFound) {
          console.error(e.message);
          continue;
        }

        if (e instanceof MissingRequiredFieldsException) {
          console.error(`Calculation with id ${estimateCalculationResponse._cr9b4_estimateid_value}: ${e.message}`);
        } else {
          throw e;
        }
      }
    }

    response.value.map(c => this.createEstimateCalculationOption(c));
    return estimateCalculations;
  }

  createDynamicsEstimateCalculation(estimateCalculation: Partial<Calculation>): Partial<DynamicsEstimateCalculation> {
    const data: Partial<DynamicsEstimateCalculation> = {};

    if (estimateCalculation.id) {
      data.cr9b4_ifoamestimatecalculationid = estimateCalculation.id.getValue();
    }

    if (estimateCalculation.strategyID) {
      data.cr9b4_calculationtype = estimateCalculation.strategyID.getValue();
    }

    return data;
  }

  private createEstimateCalculationOption(estimateCalculation: DynamicsEstimateCalculation): CalculationOptions {
    return {
      id: CalculationID.fromString(estimateCalculation.cr9b4_ifoamestimatecalculationid),
      strategyID: CalculationStrategyID.fromString(estimateCalculation.cr9b4_calculationtype),
      estimateID: EstimateID.fromString(estimateCalculation._cr9b4_estimateid_value),
    };
  }
}
