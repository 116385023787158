import {JobID} from "@modules/job/Domain/Job/VO/job-id";
import {JobOptions} from "./job-options";
import {JobContactInformation} from "./VO/job-contact-information";
import {JobIdentifiers} from "./VO/job-identifiers";

export class Job {
  readonly id: JobID;
  readonly contactInformation: JobContactInformation;
  readonly jobIdentifiers: JobIdentifiers;

  constructor(options: JobOptions) {
    if (!options.id) throw new Error("Job ID is required");
    this.id = options.id;

    this.contactInformation = new JobContactInformation(
      options.customerName,
      options.serviceAddress,
      options.phoneNumber,
      options.email
    );
    this.jobIdentifiers = new JobIdentifiers(
      options.salesJobID,
      options.jobType,
      options.campaign
    );
  }

  get customer(): { name: string, type: string } {
    return {
      name: this.contactInformation.customerName,
      type: this.jobIdentifiers.jobType || "Unknown"
    };
  }

  get location(): {
    address: { street: string }
  } {
    return {
      address: {
        street: this.contactInformation.serviceAddress || "Unknown"
      }
    };
  }

  getShortName() {
    return `${this.jobIdentifiers.salesJobID} ${this.customer.name} ${this.location.address.street}`;
  }
}
