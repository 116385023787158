import {Injectable} from "@angular/core";
import {Estimate} from "../../Domain/EstimateOption/estimate";
import {EstimateOptions} from "../../Domain/EstimateOption/estimate-options";

@Injectable({
  providedIn: 'root'
})
export class EstimateFactory {
  create(options: EstimateOptions): Estimate {
    return new Estimate(options);
  }
}
