import {dynamicsTokenProvider} from "@modules/microsoft-dynamics/Application/DI/dynamics-token-provider";
import {DynamicsWebApi} from "dynamics-web-api";
import MsAlAuthService from "../../../microsoft-auth/Domain/ms-al-auth-service";


export function dynamicsWebApiFactory(
  msalService: MsAlAuthService,
  dynamicsBaseURL: string,
): DynamicsWebApi {
  return new DynamicsWebApi({
    serverUrl: dynamicsBaseURL,
    onTokenRefresh: dynamicsTokenProvider(msalService, dynamicsBaseURL),
  });
}



