import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {MiscAreaItem} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";
import {MiscAreaOptions} from "./misc-area-options";

export class MiscArea implements CalculationArea {
  public readonly id: string;
  public readonly name: string;
  public readonly items: MiscAreaItem[];
  public readonly laborCrew: number = 0;

  constructor(params: MiscAreaOptions) {
    this.id = params.id;
    this.name = params.name;
    this.items = params.items;
  }

  get totalCost(): number {
    return this.items.reduce((acc, item) => acc + item.value, 0);
  }
}
