import {Component, Input, OnInit} from '@angular/core';
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {ProductType} from "@modules/product/Application/Configuration/product-type";
import {GetProductTypes} from "@modules/product/Application/UseCase/Query/get-product-types";
import {ProductLinkProvider} from "@modules/product/Infrastructure/Provider/product-link-provider";

@Component({
  selector: 'app-products-type-selector',
  templateUrl: './products-type-selector.component.html',
  styleUrls: ['./products-type-selector.component.scss'],
})
export class ProductsTypeSelectorComponent implements OnInit {
  @Input({required: true}) businessUnitID: string = '';
  backLink: string[] | undefined = ['gm-inputs', 'products'];
  protected productTypes: ProductType[] = [];
  protected loading = false;
  protected title: string = 'Select Product Type';

  constructor(
    private readonly getProductTypes: GetProductTypes,
    private readonly notificationService: NotificationService
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadProductTypes();
  }

  getCategoryLink(productType: ProductType): string[] {
    return ProductLinkProvider.getCategoryRoute(this.businessUnitID, productType.id);
  }

  private async loadProductTypes(): Promise<void> {
    if (!this.businessUnitID) throw new Error('Business unit is required');

    this.loading = true;

    try {
      this.productTypes = await this.getProductTypes.execute();
      this.productTypes = this.productTypes.sort(
        (a, b) => a.title.localeCompare(b.title)
      );
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }
}
