import {Job} from "../job";

export class Jobs {
  constructor(
    public readonly jobs: Job[]
  ) {
  }

  updateJob(data: Job): Jobs {
    const index = this.jobs.findIndex(job => job.id === data.id);
    if (index === -1) {
      throw new Error(`Job with ID ${data.id} not found`);
    }

    const jobs = [...this.jobs];
    jobs[index] = data;
    return new Jobs(jobs);
  }

  removeJob(data: Job): Jobs {
    const index = this.jobs.findIndex(job => job.id === data.id);
    if (index === -1) {
      throw new Error(`Job with ID ${data.id} not found`);
    }

    const jobs = [...this.jobs];
    jobs.splice(index, 1);
    return new Jobs(jobs);
  }

  filterJobs(searchTerm: string): Job[] {
    if (!searchTerm) return this.jobs;

    return this.jobs.filter(job => {
      const includesSalesJobID = job.jobIdentifiers.salesJobID.toLowerCase().includes(searchTerm.toLowerCase());

      const includesCustomerName = job.contactInformation.customerName.toLowerCase().includes(searchTerm.toLowerCase());
      const includesCustomerPhoneNumber = job.contactInformation.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase());
      const includesCustomerEmail = job.contactInformation.email?.toLowerCase().includes(searchTerm.toLowerCase());

      return includesSalesJobID || includesCustomerName || includesCustomerPhoneNumber || includesCustomerEmail;
    });
  }
}
