import {Injectable} from "@angular/core";
import {JobID} from "@modules/job/Domain/Job/VO/job-id";

@Injectable({
  providedIn: 'root'
})
export class JobLinkProvider {

  public static readonly BASE_URL = 'job';

  public static getJobRouterLink(jobID: JobID): string[] {
    return [
      ...JobLinkProvider.getBaseUrl(),
      jobID.getValue()
    ]
  }

  public static getJobsListLink(): string[] {
    return [
      ...JobLinkProvider.getBaseUrl(),
      'list'
    ]
  }

  private static getBaseUrl(): string[] {
    return ['/', JobLinkProvider.BASE_URL];
  }
}
