import {AuthenticationResult} from "@azure/msal-browser";
import MsAlAuthService from "@modules/microsoft-auth/Domain/ms-al-auth-service";

export function dynamicsTokenProvider(
  msalService: MsAlAuthService,
  dynamicsBaseURL: string,
): () => Promise<AuthenticationResult> {
  const getScopes = (): string[] => {
    return [
      dynamicsBaseURL + '/user_impersonation'
    ]
  }

  return async (): Promise<AuthenticationResult> => {
    return msalService.getAccessToken(
      getScopes()
    )
  }
}
