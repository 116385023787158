import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ActionSheetButton, ActionSheetController} from "@ionic/angular";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AuthStateObservable, AuthStateObservableDI} from "../../Domain/auth-state-observable";
import {UserLogin, UserLoginDI} from "../../Domain/user-login";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  public buttons: ActionSheetButton[] = [];
  private readonly _destroying$ = new Subject<void>();
  private actionSheet: HTMLIonActionSheetElement | undefined;

  constructor(
    @Inject(AuthStateObservableDI) private readonly authService: AuthStateObservable,
    @Inject(UserLoginDI) private readonly userLogin: UserLogin,
    private readonly router: Router,
    private readonly actionSheetCtrl: ActionSheetController
  ) {
    //1 this.authService.initialize();
  }

  ngOnInit(): void {
    this.authService.$execute()
      .pipe(takeUntil(this._destroying$))
      .subscribe((isLoggedIn) => this.processLogin(isLoggedIn));
  }

  async showActionSheet() {
    const buttons = [
      {
        text: 'Login using your Microsoft account',
        icon: 'log-in',
        handler: () => {
          this.login();
        }
      }
    ];
    this.actionSheet = await this.actionSheetCtrl.create({
      header: 'Login Actions',
      buttons: buttons
    });
    await this.actionSheet.present();
  }

  async hideActionSheet() {
    if (this.actionSheet) {
      await this.actionSheet.dismiss();
    }
  }

  login(): void {
    this.userLogin.execute();
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  private async processLogin(isLoggedIn: boolean): Promise<void> {
    if (!isLoggedIn) {
      await this.showActionSheet().then();
      return;
    }

    await this.hideActionSheet();
    await this.router.navigate(['/customer'])
  }
}
