import {CommonModule, CurrencyPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {WidthLengthComponent} from "@modules/_shared/Component/width-length/width-length.component";
import {ClearZeroDirective} from "@modules/_shared/Directives/clear-zero.directive";
import {
  RemovalCalculationStrategyFactory
} from "@modules/calculation-impl/removal-calculator/Application/Factory/removal-calculation-strategy-factory.service";
import {
  RemovalTotalComponent
} from "@modules/calculation-impl/removal-calculator/Presentation/removal-total/removal-total.component";
import {
  AddCalculationStrategyCommand
} from "@modules/calculation-strategy/Application/UseCase/Command/add-calculation-strategy.service";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {CrewSelectorComponent} from "@modules/gm-inputs/Presentation/crew-selector/crew-selector.component";
import {RemovalAreaComponent} from "./Presentation/removal-area/removal-area.component";

export const REMOVAL_CALCULATION_ID = CalculationStrategyID.fromString('removal');

@NgModule({
  declarations: [
    RemovalAreaComponent,
    RemovalTotalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    CurrencyPipe,
    WidthLengthComponent,
    CrewSelectorComponent,
    ClearZeroDirective,
  ],
  providers: [],
})
export class RemovalCalculatorModule {
  constructor(
    addCalculationStrategyCommand: AddCalculationStrategyCommand,
    strategyFactory: RemovalCalculationStrategyFactory
  ) {
    const strategy = strategyFactory.execute();
    addCalculationStrategyCommand.execute(strategy).then(r => r);
  }
}
