<ion-content>
  <div *ngIf="buttons.length">
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col class="ion-text-center" size="12">
          You need to login to access this application
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
