import {Crew} from "@modules/gm-inputs/Domain/Crew/VO/crew";

export class Crews {
  constructor(public readonly value: Crew[]) {
    if (!Array.isArray(this.value)) {
      throw new Error('Crews value must be an array');
    }

    for (let i = 0; i < this.value.length; i++) {
      if (!(this.value[i] instanceof Crew)) {
        throw new Error('Crews value must be an array of Crew objects');
      }
    }
  }

  public static getDefaultCrews(): Crew[] {
    return [
      new Crew('Lead Technician', 25),
      new Crew('Assistant Technician', 20),
      new Crew('Other Technician', 15),
    ]
  }

  getTotalHourlyRate() {
    return this.value.reduce((acc, crew) => {
      return acc + crew.rate;
    }, 0);
  }

  getCrewsUsed(requestedCrew: number): Crew[] {
    if (requestedCrew > this.value.length) {
      throw new Error('Requested crew size is greater than available crew size');
    }
    return this.value.slice(0, requestedCrew);
  }
}
