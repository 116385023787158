import {Injectable} from "@angular/core";
import {CrewInput, CrewsFactory} from "@modules/gm-inputs/Application/Factory/crews-factory.service";
import {OtherRatesFactory} from "@modules/gm-inputs/Application/Factory/other-rates-factory";
import {Crews} from "@modules/gm-inputs/Domain/Crew/crews";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";

@Injectable({
  providedIn: 'root'
})
export class GmInputsFactory {
  constructor(
    private readonly otherRatesFactory: OtherRatesFactory,
    private readonly crewsFactory: CrewsFactory,
  ) {
  }

  create(
    id: string,
    businessUnit: string,
    crews: CrewInput[],
    benefitsRate: number,
    salesTaxRate: number,
    overagePercentage: number,
    royaltyRate: number,
    commissionRate: number,
    ccPercent: number,
  ): GmInputs {
    if (!crews || !Array.isArray(crews) || crews.length === 0) {
      crews = Crews.getDefaultCrews();
    }

    return new GmInputs(
      id,
      businessUnit,
      this.crewsFactory.createCrews(crews),
      this.otherRatesFactory.create(
        benefitsRate,
        salesTaxRate,
        overagePercentage,
        royaltyRate,
        commissionRate,
        ccPercent
      )
    );
  }
}
