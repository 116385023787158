import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {EstimateID} from "@modules/estimate/Domain/EstimateOption/VO/estimate-id";

export abstract class CalculationRepository {
  abstract getCalculationsByEstimateID(estimateID: EstimateID): Promise<Calculation[]>;

  abstract addCalculationToEstimate(estimateCalculation: Partial<Calculation>): Promise<Calculation>;

  abstract deleteCalculationFromEstimate(calculationID: CalculationID): Promise<void>;

  abstract getCalculationByID<TStrategy extends CalculationStrategy>(calculationID: CalculationID): Promise<Calculation<TStrategy>>;
}
