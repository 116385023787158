import {JobID} from "@modules/job/Domain/Job/VO/job-id";
import {Jobs} from "../Aggregate/jobs";
import {Job} from "../job";
import {JobOptions} from "../job-options";

export abstract class JobRepository {
  abstract getJobs(): Promise<Jobs>;

  abstract getJob(jobID: JobID | number): Promise<Job>;

  abstract updateJob(jobOptions: JobOptions): Promise<Job>;

  abstract createJob(jobOptions: Partial<JobOptions>): Promise<Job>;

  abstract deleteJob(jobID: JobID): Promise<boolean>;
}
